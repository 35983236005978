<template>
  <form-builder
      ref="formBuilder"
      :schema="schema"
  />
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

// Config
import deliveryRequestStatusMatrix from './../../config/DeliveryRequestMatrix'

export default {
  name: 'DeliveryRequestForm',
  emits: ['change', 'locality', 'date', 'handle-delivery-services-ids'],
  props: {
    isLoading: {
      type: Boolean,
      default () {
        return false
      }
    },
    shipmentDate: {
      type: [Object, String],
      default () {
        return null
      }
    },
    hasShipDate: {
      type: Boolean,
      default () {
        return false
      }
    },
    isLocked: {
      type: Boolean,
      default () {
        return false
      }
    },
    locality: {
      type: Object,
      default () {
        return null
      }
    },
    owner: {
      type: Object,
      default () {
        return null
      }
    },
    hasTrackingNumber: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      statuses: deliveryRequestStatusMatrix,
      isDisabledServicePoints: true,
      deliveryServicesIDs: [],
      isFirstSenderLoading: true,
      isLoadDeliveryServicesIds: false,
      isEnabledSenderFilter: true,
      country: null
    }
  },
  computed: {
    ...mapGetters([
      'cleanDeliveryRequest',
      'deliveryRequest',
      'order',
      'appOptions'
    ]),
    disabled () {
      let currentStateOptions = this.statuses[0].buttons[0]

      for (let i = 0; i < this.statuses.length; i++) {
        const group = this.statuses[i]
        const state = group.buttons.find(btn => btn.id === (this.deliveryRequest || {}).state)

        if (state) {
          currentStateOptions = state
          break
        }
      }

      if (currentStateOptions.id === 'pending_queued') {
        if (!this.deliveryRequest || !this.deliveryRequest.id) {
          return false
        }

        return !currentStateOptions.editableField
      }

      return !currentStateOptions.editableField || this.isLocked
    },
    isDisabledEstimatedCost () {
      return this.disabled ||
          (this.order && this.order.paymentState === 'not_paid') ||
          (!this.order && this.deliveryRequest && this.deliveryRequest.eav['delivery-request-products-order']) ||
          (this.deliveryRequest && !this.deliveryRequest.eav['delivery-request-products-order'] && this.deliveryRequest.places && this.deliveryRequest.places.length > 0)
    },
    isDisabledPaymentCost () {
      return this.disabled ||
          this.order ||
          (this.deliveryRequest && this.deliveryRequest.eav['delivery-request-products-order']) ||
          (this.deliveryRequest && !this.deliveryRequest.eav['delivery-request-products-order'] && this.deliveryRequest.places && this.deliveryRequest.places.length > 0)
    },
    schema () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            id: 'drForm',
            styleClasses: 'row',
            fields: [
              {
                if: this.hasTrackingNumber,
                type: 'input',
                inputType: 'text',
                field: 'trackingNumber',
                value: this.deliveryRequest && this.deliveryRequest.trackingNumber,
                required: true,
                disabled: this.disabled,
                label: this.$t('Tracking Number'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                onChange: (trackingNumber) => {
                  this.updateDeliveryRequest({ trackingNumber })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'multiselect',
                label: this.hasTrackingNumber
                    ? this.$t('Recipient')
                    : this.$t('Sender'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'sender',
                value: this.deliveryRequest && this.deliveryRequest._embedded.sender,
                required: true,
                disabled: this.disabled || (!!this.cleanDeliveryRequest && !!this.cleanDeliveryRequest._embedded.sender),
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'in', field: 'state', values: ['active', 'blocked'] }
                    ]
                  }

                  if (this.owner) {
                    query.filter.push({
                      type: 'eq',
                      field: 'owner',
                      value: this.owner.id
                    })
                  }

                  return this.$service.sender.getAll(query)
                },
                onChange: (value) => {
                  const data = {
                    sender: value
                  }

                  this.isFirstSenderLoading = true

                  if (
                      value &&
                      this.deliveryRequest._embedded.sender &&
                      this.deliveryRequest._embedded.sender._embedded.defaultSenderProfile &&
                      (!value._embedded.defaultSenderProfile || value._embedded.defaultSenderProfile.id !== this.deliveryRequest._embedded.sender._embedded.defaultSenderProfile.id)
                  ) {
                    this.resetDeliveryServiceField()
                    this.resetRateField()
                    this.resetServicePointField()
                    data.deliveryService = null
                    data.rate = null
                    data.servicePoint = null
                  }

                  this.updateDeliveryRequestEmbedded(data)
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                ref: 'deliveryService',
                type: 'multiselect',
                label: this.$t('Shipping carrier'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'deliveryService',
                value: this.deliveryRequest && this.deliveryRequest._embedded.deliveryService,
                required: true,
                disabled: this.disabled || !this.deliveryRequest || !this.deliveryRequest._embedded.sender,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row || !row.logo) {
                    return ''
                  }
                  if (row.logo.includes('http')) {
                    return `${row.logo}`
                  } else {
                    return `${this.appOptions.defaultServer}${row.logo}`
                  }

                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'name', direction: 'desc' }
                    ],
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (this.country && this.isEnabledSenderFilter) {
                    query.filter.push({ type: 'eq', field: 'country', value: this.country })
                  }

                  return this.$service.deliveryService.getAll(query)
                },
                onChange: (deliveryService) => {
                  this.updateDeliveryRequestEmbedded({ deliveryService, rate: null, servicePoint: null })
                  this.resetRateField()
                  this.resetServicePointField()
                  this.$emit('change', this.deliveryRequest)
                },
                btnStyleClasses: 'q-pl-sm',
                buttons: [
                  {
                    id: 'showOrHide',
                    label: this.isEnabledSenderFilter
                        ? this.$t('Show for all')
                        : this.$t('Hide invalid'),
                    color: 'dark',
                    styleClasses: 'fit',
                    onClick: () => {
                      this.isEnabledSenderFilter = !this.isEnabledSenderFilter
                      this.resetDeliveryServiceField()
                      this.resetRateField()
                    }
                  }
                ]
              },
              {
                ref: 'rate',
                type: 'multiselect',
                label: this.$t('Shipping rate'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'rate',
                value: this.deliveryRequest && this.deliveryRequest._embedded.rate,
                required: true,
                disabled: this.disabled ||
                    !this.deliveryRequest ||
                    !this.deliveryRequest._embedded.sender,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row._embedded?.deliveryService?.logo) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  query['order-by'] = [
                    { type: 'field', field: 'deliveryService', direction: 'desc' },
                    { type: 'field', field: 'name', direction: 'desc' }
                  ]

                  if (this.deliveryRequest._embedded.deliveryService) {
                    query.filter.push({
                      type: 'eq',
                      field: 'deliveryServices',
                      value: this.deliveryRequest._embedded.deliveryService.id
                    })
                  } else if (this.isEnabledSenderFilter && this.deliveryServicesIDs && this.deliveryServicesIDs.length > 0) {
                    query.filter.push({
                      type: 'in',
                      field: 'deliveryServices',
                      values: this.deliveryServicesIDs
                    })
                  }

                  return this.$service.deliveryServiceRate.getAll(query)
                },
                onChange: (rate) => {
                  const types = ['service_point', 'self_service_point', 'simple']
                  this.isDisabledServicePoints = !types.includes((rate || {}).type)

                  this.updateDeliveryRequestEmbedded({ rate, servicePoint: null })
                  this.resetServicePointField()
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                ref: 'servicePoint',
                type: 'multiselect',
                label: this.$t('Pick up point'),
                wrapperStyleClasses: 'col-12 col-md-4 q-pa-xs',
                field: 'servicePoint',
                value: this.deliveryRequest && this.deliveryRequest._embedded.servicePoint,
                required: true,
                disabled: this.isDisabledServicePoints ||
                    (this.deliveryRequest && (!this.deliveryRequest._embedded || !this.deliveryRequest._embedded.rate)) ||
                    this.disabled,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                description: (row) => {
                  if (!row) {
                    return ''
                  }

                  return `${row.rawAddress}, ${this.$t('extId')}: ${row.extId}, ${this.$t('phone')}: ${row.phone || 'none'}`
                },
                imagePreview: (row) => {
                  if (!row || !row._embedded.deliveryService) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (
                      this.deliveryRequest._embedded.rate &&
                      this.deliveryRequest._embedded.rate._embedded &&
                      this.deliveryRequest._embedded.rate._embedded.deliveryService
                  ) {
                    query.filter.push(this.createDeliveryServiceFilter(this.deliveryRequest._embedded.rate._embedded.deliveryService))
                  }

                  if (this.locality) {
                    query.filter.push({
                      type: 'eq',
                      field: 'locality',
                      value: this.locality.id
                    })
                  }

                  return this.$service.servicePoint.getAll(query)
                },
                onChange: (servicePoint) => {
                  this.updateDeliveryRequestEmbedded({ servicePoint })

                  const locality = (servicePoint && servicePoint._embedded.locality) || null
                  this.$emit('locality', locality)
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'estimatedCost',
                value: this.deliveryRequest && this.deliveryRequest.estimatedCost,
                required: true,
                disabled: this.isDisabledEstimatedCost,
                label: this.$t('Estimated cost'),
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs',
                onChange: (estimatedCost) => {
                  this.updateDeliveryRequest({ estimatedCost })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'payment',
                value: this.deliveryRequest && this.deliveryRequest.payment,
                disabled: this.isDisabledPaymentCost,
                required: true,
                label: this.$t('Payment (CoD)'),
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs',
                onChange: (payment) => {
                  this.updateDeliveryRequest({ payment })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                model: 'retailPrice',
                value: this.deliveryRequest && this.deliveryRequest.retailPrice,
                disabled: this.disabled,
                label: this.$t('Delivery price'),
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs',
                onChange: (retailPrice) => {
                  this.updateDeliveryRequest({ retailPrice })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                label: this.$t('Total'),
                fi: 'totalSum',
                value: this.deliveryRequest && this.deliveryRequest.totalSum,
                disabled: true,
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs'
              },
              {
                type: 'input',
                inputType: 'text',
                field: 'senderName',
                value: this.deliveryRequest && this.deliveryRequest.senderName,
                disabled: this.disabled,
                label: this.$t('Sender name'),
                wrapperStyleClasses: 'col-6 col-md-4 q-pa-xs',
                onChange: (senderName) => {
                  this.updateDeliveryRequest({ senderName })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                if: this.hasShipDate,
                type: 'date',
                field: 'shipmentDate',
                value: this.deliveryRequest && this.shipmentDate,
                disabled: this.disabled,
                label: this.$t('Ship date'),
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs',
                get (value) {
                  if (!value) {
                    return
                  }

                  if (typeof value === 'object') {
                    return value.date
                  }

                  return value
                },
                onChange: (shipmentDate) => {
                  this.$emit('date', shipmentDate)
                }
              },
              {
                type: 'date',
                field: 'deliveryDate',
                value: this.deliveryRequest && this.deliveryRequest.deliveryDate,
                disabled: this.disabled,
                label: this.$t('Delivery date'),
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs',
                get (value) {
                  if (!value) {
                    return
                  }

                  if (typeof value === 'object' && value.date) {
                    return value.date
                  }

                  return value
                },
                onChange: (deliveryDate) => {
                  this.updateDeliveryRequest({ deliveryDate })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'time',
                field: 'deliveryTimeStart',
                value: this.deliveryRequest && this.deliveryRequest.deliveryTimeStart,
                disabled: this.disabled,
                label: this.$t('Delivery time start'),
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs',
                onChange: (deliveryTimeStart) => {
                  this.updateDeliveryRequest({ deliveryTimeStart })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'time',
                field: 'deliveryTimeEnd',
                value: this.deliveryRequest && this.deliveryRequest.deliveryTimeEnd,
                disabled: this.disabled,
                label: this.$t('Delivery time end'),
                wrapperStyleClasses: 'col-6 col-md-2 q-pa-xs',
                onChange: (deliveryTimeEnd) => {
                  this.updateDeliveryRequest({ deliveryTimeEnd })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'tax',
                value: this.deliveryRequest && this.deliveryRequest.tax,
                required: true,
                disabled: true,
                label: this.$t('Tax'),
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: (tax) => {
                  this.updateDeliveryRequest({ tax })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'deliveryByRatePrice',
                value: this.deliveryRequest && this.deliveryRequest.price,
                required: true,
                disabled: true,
                label: this.$t('Delivery by rate price'),
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: (price) => {
                  this.updateDeliveryRequest({ price })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'discount',
                value: this.order && this.order.discountPrice,
                required: true,
                disabled: true,
                label: this.$t('Discount'),
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: (discountPrice) => {

                  this.updateDeliveryRequest({ discountPrice })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'number',
                field: 'finalPrice',
                value: this.handleFinalPrice(),
                required: true,
                disabled: true,
                label: this.$t('Final price'),
                wrapperStyleClasses: 'col-6 col-md-3 q-pa-xs',
                onChange: (discountPrice) => {

                  this.updateDeliveryRequest({ discountPrice })
                  this.$emit('change', this.deliveryRequest)
                }
              },
              {
                type: 'input',
                inputType: 'text',
                label: this.$t('Comment for the shipping carrier'),
                field: 'recipientComment',
                value: this.deliveryRequest && this.deliveryRequest.recipientComment,
                disabled: this.disabled,
                max: 255,
                hint: this.$t('Max 255 characters.'),
                wrapperStyleClasses: 'col q-pa-xs',
                onChange: (recipientComment) => {
                  this.updateDeliveryRequest({ recipientComment })
                  this.$emit('change', this.deliveryRequest)
                }
              }
            ]
          }
        ]
      }
    }
  },
  watch: {
    deliveryRequest (newValue) {
      if (newValue && newValue._embedded && newValue._embedded.sender && this.isFirstSenderLoading) {
        this.isFirstSenderLoading = false
        this.loadDeliveryServicesIDs(newValue._embedded.sender)
      }

      this.setAutoIsDisabledServicePoints()
    },
    locality (newValue, oldValue) {
      if (!this.deliveryRequest) {
        return
      }

      if (
          (newValue || {}).id !== (oldValue || {}).id &&
          this.deliveryRequest &&
          this.deliveryRequest._embedded.servicePoint &&
          this.deliveryRequest._embedded.servicePoint._embedded.locality.id &&
          this.deliveryRequest._embedded.servicePoint._embedded.locality.id !== (newValue || {}).id
      ) {
        this.updateDeliveryRequest({
          _embedded: {
            ...this.deliveryRequest._embedded,
            servicePoint: null
          }
        })

        this.resetServicePointField()
      }
    },
    owner () {
      this.loadDefaultSender()
    }
  },
  mounted () {
    this.setAutoIsDisabledServicePoints()

    if (this.deliveryRequest && this.deliveryRequest._embedded && this.deliveryRequest._embedded.senderAddress && this.isFirstSenderLoading) {
      this.isFirstSenderLoading = false

      return this.$service.address.get(this.deliveryRequest._embedded.senderAddress.id)
          .then(address => {
            if (address) {
              const parts = address._embedded.locality._embedded.country._links.self.href.split('/')
              return this.getDeliveryServicesByCountry(parts[parts.length - 1])
            } else {
                return null
            }
          })
    }

    if (this.deliveryRequest && this.deliveryRequest._embedded && this.deliveryRequest._embedded.sender && this.isFirstSenderLoading) {
      this.isFirstSenderLoading = false
      return this.loadDeliveryServicesIDs(this.deliveryRequest._embedded.sender)
    }

    if (this.deliveryRequest && this.deliveryRequest._embedded && !this.deliveryRequest._embedded.sender) {
      this.loadDefaultSender()
    }
  },
  methods: {
    ...mapMutations([
      'updateDeliveryRequest',
      'updateDeliveryRequestEmbedded'
    ]),
    handleFinalPrice () {
      let finalPrice = 0
      if(this.deliveryRequest) {
        if( this.deliveryRequest.estimatedCost) {
          finalPrice += this.deliveryRequest.estimatedCost
        }
        if (this.deliveryRequest.retailPrice) {
          finalPrice += this.deliveryRequest.retailPrice
        }
        if (this.deliveryRequest.tax) {
          finalPrice += this.deliveryRequest.tax
        }
      }
      if(this.order) {
        if (this.order.discountPrice) {
          finalPrice -= this.order.discountPrice
        }
      }
      return finalPrice
    },
    getFormRef () {
      return Array.isArray(this.$refs.formBuilder.$refs.drForm)
          ? this.$refs.formBuilder.$refs.drForm[0].$refs
          : this.$refs.formBuilder.$refs.drForm.refs
    },
    getDeliveryServiceRef () {
      return this.getFormRef().deliveryService[0].$refs.deliveryService
    },
    getRateRef () {
      return this.getFormRef().rate[0].$refs.rate
    },
    getServicePointRef () {
      return this.getFormRef().servicePoint[0].$refs.servicePoint
    },
    resetDeliveryServiceField () {
      this.getDeliveryServiceRef().reset()
    },
    resetRateField () {
      this.getRateRef().reset()
    },
    resetServicePointField () {
      this.getServicePointRef().reset()
    },
    // Disable service point if rate is courier
    setAutoIsDisabledServicePoints () {
      this.isDisabledServicePoints =
          (!this.deliveryRequest || !this.deliveryRequest._embedded) ||
          (this.deliveryRequest &&
              this.deliveryRequest._embedded &&
              this.deliveryRequest._embedded.rate &&
              this.deliveryRequest._embedded.rate.type === 'courier')
    },
    // We need to set sender if it is only one
    loadDefaultSender () {
      const query = {
        per_page: 5,
        page: 1,
        filter: [
          { type: 'eq', field: 'state', value: 'active' }
        ]
      }

      if (this.owner) {
        query.filter.push({
          type: 'eq',
          field: 'owner',
          value: this.owner.id
        })
      }

      return this.$service.sender.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems === 1) {
              const sender = items[0]

              if (this.deliveryRequest._embedded.sender && this.deliveryRequest._embedded.sender.id === sender.id) {
                return
              }

              this.updateDeliveryRequest({
                _embedded: {
                  ...this.deliveryRequest._embedded,
                  sender
                }
              })

              return this.loadDeliveryServicesIDs(sender)
            }
          })
    },
    extractProfileID (sender) {
      if (sender._embedded.defaultSenderProfile.id) {
        return sender._embedded.defaultSenderProfile.id
      }

      const parts = sender._embedded.defaultSenderProfile._links.self.href.split('/')
      return parts[parts.length - 1]
    },
    // Load delivery service ids for country in which is sender
    loadDeliveryServicesIDs (value) {
      if (!value?._embedded?.defaultSenderProfile) {
        if (value._embedded?.parent && value._embedded.parent._embedded) {
          const id = this.extractProfileID(value._embedded.parent)
          return this.loadDeliveryServicesByAddress(id)
        }

        this.deliveryServicesIDs = []
        return
      }

      const id = this.extractProfileID(value)
      return this.loadDeliveryServicesByAddress(id)
    },
    loadDeliveryServicesByAddress (value) {
      this.isLoadDeliveryServicesIds = true
      const query = {
        page: 1,
        per_page: 25,
        filter: [
          { type: 'eq', field: 'profile', value }
        ]
      }

      return this.$service.address.getAll(query)
          .then(({ items, totalItems }) => {
            if (totalItems > 0) {
              if (!items[0]._embedded.locality) {
                this.deliveryServicesIDs = []
                this.isLoadDeliveryServicesIds = false
                this.$emit('handle-delivery-services-ids', this.deliveryServicesIDs)
                return
              }

              this.country = items[0]._embedded.locality._embedded.country._links.self.href.split('/').pop()
              return this.getDeliveryServicesByCountry(this.country)
            }

            this.isLoadDeliveryServicesIds = false
          })
    },
    getDeliveryServicesByCountry (value) {
      const query = {
        per_page: 250,
        page: 1,
        filter: [
          { type: 'eq', field: 'country', value }
        ]
      }

      return this.$service.deliveryService.getAll(query)
          .then(({ items }) => {
            this.deliveryServicesIDs = items.map(({ id }) => id)
            this.isLoadDeliveryServicesIds = false
            this.$emit('handle-delivery-services-ids', this.deliveryServicesIDs)
          })
    },
    createDeliveryServiceFilter (deliveryService) {
      if (deliveryService.id) {
        return { type: 'eq', field: 'deliveryService', value: deliveryService.id }
      }

      const link = deliveryService._links.self.href
      const index = link.lastIndexOf('/')
      const value = link.slice(index + 1)

      return { type: 'eq', field: 'deliveryService', value }
    }
  }
}
</script>
