<template>
  <div>
    <q-list
        bordered
        separator
        class="rounded"
    >
      <q-item
          v-if="title"
          class="block text-center"
      >

        <div class="row fit items-center">
          <h6 class="col q-my-none justify-center">
            {{ title }}
          </h6>
        </div>

        <p
            v-if="disabled"
            class="text-subtitle1 q-mb-none text-grey-8"
        >
          <q-icon
              name="error"
              size="1.5rem"
          />

          {{ $t('Editing of this shipment isn\'t available because it is bounded to an order') }}
        </p>
      </q-item>

      <q-item
          v-for="(place, i) in currentPlaces"
          @dragend="drop($event, place)"
          @dragover="allowDrop($event, place)"
          :key="place.id"
          class="q-pa-none"
      >
        <div class="full-width">
          <div class="row items-center full-width border-bottom q-py-sm q-px-md">
            <div class="row">
              <div class="col-1">
                <q-btn
                    color="negative"
                    :disable="disabled || currentPlaces.length < 2"
                    icon="delete"
                    flat
                    @click="handleRemovePlace(place)"
                />
              </div>

              <div class="col-3">
                <h6 class="q-my-none q-ml-sm"
                    v-if="(currentPlaces[0].eav && currentPlaces[0].eav['delivery-services-request-place-is-pool'])">
                  <div v-if="place.priority===0">
                    {{
                      $t('Pool') + ' (' + place.id + ', ' + place.priority + (place.trackingNumber ? ', ' + place.trackingNumber : '') + ')'
                    }}

                    <q-badge :color="stateColors[place.state]" :label="$t(place.state)"/>
                  </div>
                  <div v-else>
                    {{
                      $t('Box No') + i + ' (' + place.id + ', ' + place.priority + (place.trackingNumber ? ', ' + place.trackingNumber : '') + ')'
                    }}

                    <q-badge :color="stateColors[place.state]" :label="$t(place.state)"/>
                  </div>
                </h6>

                <h6 class="q-my-none q-ml-sm" v-else>
                  {{
                    $t('Box No') + (i + 1) + ' (' + place.id + ', ' + place.priority + (place.trackingNumber ? ', ' + place.trackingNumber : '') + ')'
                  }}

                  <q-badge :color="stateColors[place.state]" :label="$t(place.state)"/>
                </h6>
              </div>

              <div class="col">
                <editable-input-field
                    :label="$t('Weight')"
                    :input-label="`${$t('Weight')} (${$t(appOptions.defaultDimensions.weightUnit)})`"
                    :value="place?.weight"
                    :unit="appOptions.defaultDimensions.weightUnit"
                    :editable="true"
                    :update-function="newValue => updatePlaceDimensions('weight', newValue, place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>
              </div>

              <div class="col">
                <div class="q-my-none q-mx-lg">
                  <editable-input-field
                      label="Width"
                      :input-label="`${$t('Width')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                      :value="place?.dimensions?.x"
                      :unit="appOptions.defaultDimensions.dimensionUnit"
                      :editable="true"
                      :update-function="newValue => updatePlaceDimensions('x', newValue, place)"
                      @change-catch-all="handleCloseEditItem"
                  ></editable-input-field>
                </div>
              </div>

              <div class="col">
                <editable-input-field
                    label="Height"
                    :input-label="`${$t('Height')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                    :value="place?.dimensions?.y"
                    :unit="appOptions.defaultDimensions.dimensionUnit"
                    :editable="true"
                    :update-function="newValue => updatePlaceDimensions('y', newValue, place)"
                    @change-catch-all="handleCloseEditItem"
                ></editable-input-field>
              </div>

              <div class="col">
                <div class="q-my-none q-mx-lg">
                  <editable-input-field
                      label="Length"
                      :input-label="`${$t('Length')} (${$t(appOptions.defaultDimensions.dimensionUnit)})`"
                      :value="place?.dimensions?.z"
                      :unit="appOptions.defaultDimensions.dimensionUnit"
                      :editable="true"
                      :update-function="newValue => updatePlaceDimensions('z', newValue, place)"
                      @change-catch-all="handleCloseEditItem"
                  ></editable-input-field>
                </div>
              </div>

              <div class="col">
                <q-btn
                    color="light-blue-9"
                    :disable="disabled"
                    :label="$t('Add product')"
                    no-caps
                    @click="handleAddItem(place)"
                />
              </div>
            </div>

          </div>

          <q-table
              row-key="id"
              class="full-width table--only-header"
              :rows-per-page-label="$t('Rows per page')"
              :rows="place.items || []"
              :columns="columns"
              :pagination="{ page: 1, rowsPerPage: 250, totalRowsNumber: (place.items || []).length }"
              virtual-scroll
              flat
          >
            <template v-slot:body="props">
              <product-row
                  @dragstart="drag($event, props.row , place)"
                  @ondragover="allowDrop($event)"
                  :data="props"
                  :place="place"
                  :disabled="disabled"
                  @dblclick="handleEditItem(place, props.row)"
                  @delete="handleRemove(place, props.row)"
              />
            </template>

            <template v-slot:bottom-row>
              <q-tr>
                <q-td class="border-top border-right text-center">
                  {{ $t('Total') }}
                </q-td>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top"/>

                <q-td class="border-top">
                  {{ getPlaceItemsCount(place) }}
                </q-td>

                <q-td class="border-top">
                  {{ getPlaceTotalSum(place).toFixed(2) }}
                </q-td>

                <q-td class="border-top"/>
              </q-tr>
            </template>
          </q-table>
        </div>
      </q-item>

      <q-item
          v-if="currentPlaces.length > 0"
          class="justify-center text-h6"
      >
        <span class="q-mr-md">{{ $t('Total') + ': ' + total.price.toFixed(2) }}</span>

        <span>{{ $t('Total Items') + ': ' + total.count }}</span>
      </q-item>

      <q-item
          v-else
          class="justify-center text-subtitle1 items-center"
      >
        <span>
          {{ $t('There is no boxes with products. ') }}
        </span>

        <span v-if="!disabled">
          {{ $t('You can add box via button below.') }}
        </span>
      </q-item>

      <q-item class="justify-center items-center q-pb-md">
        <q-btn
            color="light-blue-9"
            class="q-mt-sm"
            :disable="disabled"
            :label="this.currentPlaces && this.currentPlaces.length === 1 ? $t('Split into packages') : $t('Add box') "
            @click="handleAddPlace"
        >
          <q-tooltip v-if="this.currentPlaces && this.currentPlaces.length === 1">
            {{
              'When you click this button, first place will be converted into items pool so you can put it into boxes.'
            }}
          </q-tooltip>
        </q-btn>
      </q-item>
    </q-list>

    <d-r-product
        ref="drProduct"
        :disabled="disabled"
        :delivery="deliveryRequest"
        @change-catch="handleCloseEditItem"
        @submit="handleItemSubmit"
    />

    <confirm-modal ref="confirmModal"/>
  </div>
</template>

<script>
// Components
import ConfirmModal from '../confirm-modal/ConfirmModal.vue'
import DRProduct from './DRProduct.vue'
import ProductRow from './ProductRow.vue'
import { mapActions, mapGetters } from 'vuex'
import EditableInputField from '@/apps/app/components/delivery-services/EditableInputField.vue'

export default {
  name: 'DRProducts',
  emits: ['change', 'change-catch-all'],
  components: {
    EditableInputField,
    DRProduct,
    ProductRow,
    ConfirmModal
  },
  props: {
    title: {
      type: String,
      default () {
        return null
      }
    },
    disabled: {
      type: Boolean,
      default () {
        return false
      }
    },
    externalDeliveryRequest: {
      type: [String, Number],
      default () {
        return null
      }
    },
    places: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      place: null,
      product: null,
      currentPlaces: [],
      dragStarter: null,
      dragEnder: null,
      dragItem: null,
      height: null,
      width: null,
      length: null,
      placeToChange: null,
      columns: [
        {
          name: 'selected',
          label: `${this.$t('Mark')} (${this.$t('optional')})`,
          align: 'center',
          classes: 'border-right',
          headerClasses: 'border-right'
        },
        {
          name: 'image',
          label: this.$t('Image'),
          align: 'left'
        },
        {
          name: 'id',
          label: this.$t('Id'),
          align: 'left'
        },
        {
          name: 'name',
          label: this.$t('Name'),
          align: 'left'
        },
        {
          name: 'payment',
          label: this.$t('Payment'),
          align: 'left'
        },
        {
          name: 'estimatedCost',
          label: this.$t('Estimated Cost'),
          align: 'left'
        },
        {
          name: 'count',
          label: this.$t('Count'),
          align: 'left'
        },
        {
          name: 'total',
          label: this.$t('Total'),
          align: 'left'
        },
        {
          name: 'actions',
          label: '',
          align: 'right'
        }
      ],
      stateColors: {
        active: 'success',
        filling: 'danger',
        inactive: 'dark text-white',
      },
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'deliveryRequest',
    ]),
    total () {
      return this.currentPlaces.reduce((acc, place) => {
        acc.price += this.getPlaceTotalSum(place)
        acc.count += place.items.reduce((acc, item) => {
          return acc + Number(item.count || 0)
        }, 0)

        return acc
      }, { count: 0, price: 0 })
    }
  },
  watch: {
    places (value) {
      this.currentPlaces = this.copyPlaces(value)
      this.currentPlaces.sort((a, b) => {
        return a.priority - b.priority
      })
    }
  },
  mounted () {
    if (!this.deliveryRequest && this.externalDeliveryRequest) {
      this.setDeliveryRequest(this.externalDeliveryRequest)
    }

    this.currentPlaces = this.copyPlaces(this.places)
    this.currentPlaces.sort((a, b) => {
      return a.priority - b.priority
    })
  },
  methods: {
    ...mapActions([
      'setDeliveryRequest',
    ]),
    getPlaceItemsCount (place) {
      return place.items.reduce((sum, item) => {
        return sum + (Number(item.count) || 0)
      }, 0)
    },
    getPlaceTotalSum (place) {
      return place.items.reduce((sum, item) => {
        return sum + ((Number(item.count) || 0) * (Number(item.payment) || 0))
      }, 0)
    },
    copyPlaces (places) {
      if (!Array.isArray(places)) {
        return []
      }

      return places.map(place => {
        return {
          ...place,
          items: place.items.map(item => {
            return {
              ...item,
              dimensions: !item.dimensions || typeof item.dimensions === 'string'
                  ? { x: 0, y: 0, z: 0 }
                  : item.dimensions
            }
          })
        }
      })
    },
    handleItemSubmit (item, place, oldPlace, copyMade = false) {
      let updatedPlace
      this.currentPlaces = this.currentPlaces.map(p => {
        if (p.id === place.id) {
          let hasItem = false

          p.items = p.items.map(i => {
            if (i.id === item.id) {
              hasItem = true
              return item
            }

            return i
          })

          if (!hasItem) {
            p.items.push(item)
          }
          if (copyMade && hasItem) {
            p.items.push(item)
          }
          updatedPlace = p
        }

        return p
      })

      if (updatedPlace) {
        this.currentPlaces = this.currentPlaces.map(e => {
          if (e.id === oldPlace.id && !copyMade) {
            e.items = e.items.filter(i => i.id !== item.id)
            this.handleCloseItemModal()
            return e
          }
          this.handleCloseItemModal()
          return e
        })

        this.$emit('change', this.currentPlaces)
      }
      this.handleCloseItemModal()
    },
    handleCloseEditItem (value) {
      this.$eventBus.update('catchAll', {
        catchAll: value
      })

      this.$emit('change-catch-all', value)
    },
    handleEditItem (place, item) {
      this.place = place
      this.product = item
      this.$emit('change-catch-all', false)
      this.$refs.drProduct.open(item, place)
    },
    updatePlaceDimensions (state, value, place) {
      if (Number(value) <= 0) {
        return
      }

      if (state === 'weight') {
        place.weight = value
        return this.$service.deliveryServicePlace.save({
          weight: value
        }, place.id).then(updatedPlace => {
          for (let i = 0; i < this.deliveryRequest.places.length; i++) {
            if (this.deliveryRequest.places[i].id === updatedPlace.id) {
              this.deliveryRequest.places[i] = updatedPlace

              break
            }
          }
        })
      } else {
        if (!place.dimensions) {
          place.dimensions = {}
        }

        place.dimensions[state] = value

        // Check if all dimensions (x, y, z) are defined and then save them
        if (place.dimensions.x !== undefined && place.dimensions.y !== undefined && place.dimensions.z !== undefined) {
          return this.$service.deliveryServicePlace.save({
            dimensions: {
              x: place.dimensions.x,
              y: place.dimensions.y,
              z: place.dimensions.z
            }
          }, place.id).then(updatedPlace => {
            for (let i = 0; i < this.deliveryRequest.places.length; i++) {
              if (this.deliveryRequest.places[i].id === updatedPlace.id) {
                this.deliveryRequest.places[i] = updatedPlace

                break
              }
            }
          })
        } else {
          return
        }
      }

      // Save the updated place object
      let result = {
        dimensions: {
          ...place.dimensions,
          [state]: value
        }
      }

      this.$service.deliveryServicePlace.save({ result }, place.id)
    },
    handleRemovePlace (place) {
      return this.$refs.confirmModal.show({ description: this.$t('The box will be deleted with all products in it. Are you sure?') })
          .then(isOk => {
            if (isOk) {
              this.$service.deliveryServicePlace.save({ state: 'deleted' }, place.id)
                  .then(place => {
                    this.currentPlaces = this.currentPlaces.filter(({ id }) => id !== place.id)
                    this.$emit('change', this.currentPlaces)
                  })
            }
          })
    },
    handleAddPlace () {
      const data = {
        deliveryRequest: this.deliveryRequest.id ?? this.deliveryRequest,
        dimensions: null,
        volume: null,
        weight: null
      }

      if (this.currentPlaces && this.currentPlaces.length === 1) {
        data.priority = this.currentPlaces.length + 1
      }

      return this.$service.deliveryServicePlace.save(data)
          .then(place => {
            this.currentPlaces = [...this.currentPlaces, place]
            this.$emit('change', this.currentPlaces)
          })
    },
    handleRemove (place, item) {
      let updatedPlace = place

      this.currentPlaces = this.currentPlaces
          .reduce((acc, p) => {
            if (p.id === place.id) {
              p.items = p.items.filter(i => i.id !== item.id)
              updatedPlace = p

              if (p.items.length <= 0) {
                return acc
              }
            }

            acc.push(p)
            return acc
          }, [])

      this.$service.deliveryServicePlaceItems.save({ state: 'deleted' }, item.id)
      return this.savePlace(updatedPlace)
          .then(() => {
            this.$emit('change', this.currentPlaces)
          })
    },
    handleAddItem (place) {
      this.place = place
      this.product = null
      this.$refs.drProduct.open()
    },
    handleCloseItemModal () {
      this.$refs.drProduct.close()
    },
    createNewItem (item) {
      const blockedFields = ['_links', 'id', '_embedded', 'created', 'updated', 'price']

      const newItem = Object.keys(item).reduce((c, key) => {
        if (blockedFields.includes(key)) {
          return c
        }

        if (item[key] === undefined || item[key] === null) {
          return c
        }

        c[key] = item[key]
        return c
      }, {})

      if (!newItem.dimensions || typeof newItem.dimensions === 'string') {
        newItem.dimensions = {
          x: 1,
          y: 1,
          z: 1
        }
      }

      return newItem
    },
    savePlace (place) {
      if (place.items.length <= 0) {
        return this.$service.deliveryServicePlace.save({ state: 'deleted' }, place.id)
      }

      const items = place.items.map(i => {
        const item = this.createNewItem(i)
        item.place = place.id
        return item
      })

      return this.$service.deliveryServicePlace.save({ deliveryRequest: place._embedded.deliveryRequest.id }, place.id)
    },
    allowDrop (ev, endPlace) {
      ev.preventDefault()
      this.dragEnder = endPlace
    },
    drag (event, item, startPlace) {
      this.dragItem = item
      this.dragStarter = startPlace
    },
    drop (ev, list) {
      if (this.dragStarter && this.dragEnder && this.dragItem && this.dragStarter !== this.dragEnder) {
        this.$service.deliveryServicePlaceItems.save({ 'place': this.dragEnder.id }, this.dragItem.id)
        this.handleItemSubmit(this.dragItem, this.dragEnder, this.dragStarter)
      }

    }
  }
}
</script>

