<template>
  <q-dialog
    v-model="isOpen"
    persistent
  >
    <q-card style="min-width: 40vw;">
      <q-card-section>
        <div class="text-h6 text-center">
          {{ $t('Add tracking number') }}
        </div>
      </q-card-section>

      <q-card-section>
        <q-input
          v-model="trackingNumber"
          type="text"
          color="white"
          standout="bg-teal text-white"
          :label="$t('Tracking number')"
          class="text-white"
          label-color="light"
        />
      </q-card-section>

      <q-card-section class="text-center">
        <q-btn
          class="q-mr-sm"
          color="dark"
          text-color="white"
          :label="$t('Discard')"
          no-caps
          @click="hide"
        />

        <q-btn
          color="light-blue-9"
          text-color="white"
          :label="$t('Save')"
          no-caps
          @click="handleOk"
        />
      </q-card-section>
    </q-card>
  </q-dialog>
</template>

<script>

export default {
  name: 'TrackingNumberModal',
  data () {
    return {
      isOpen: false,
      deliveryRequest: null,
      trackingNumber: ''
    }
  },
  methods: {
    resolve () {},
    reject () {},
    show (deliveryRequest) {
      this.isOpen = true
      this.deliveryRequest = deliveryRequest
    },
    hide () {
      this.isOpen = false
    },
    handleOk () {
      return this.$service.deliveryRequest.save({ trackingNumber: this.trackingNumber }, this.deliveryRequest.id)
        .then(item => {
          this.hide()
          this.$emit('submit', item)
        })
    }
  }
}
</script>
