<template>
  <div>
    <action-header
        v-if="!noHeader"
        :is-loading="!deliveryRequest"
        :actions-model="headerModel"
        :is-pin="false"
        :page="page"
    />

    <div
        v-if="deliveryRequest"
    >
      <div style="overflow: auto;">
        <div
            class="row border q-ma-md rounded"
            style="min-width: 900px;"
        >
          <div class="row col-12 items-center q-pa-sm border-bottom">
            <div class="col-2">
              <h6 class="q-ma-none text-subtitle1 text-bold">
                {{ $t('Client Id') + ': ' + (deliveryRequest.clientExtId || deliveryRequest.extId) }}
              </h6>
            </div>
            <div class="col-3">
              <p class="q-my-none q-mx-sm text-subtitle1">
                {{
                  $t('Shipment') + ': '
                }}
                <span @click="openDR(deliveryRequest.id)" class="doc-link cursor-pointer">{{
                    deliveryRequest.id
                  }}</span>
                {{
                  '/' + $moment(deliveryRequest.created.date).format(appOptions.formats.date)
                }}
              </p>
            </div>
            <div class="col-2">
              <p class="q-my-none q-mx-sm text-subtitle1">
                <span v-if="deliveryRequest._embedded && deliveryRequest._embedded.order">
                  {{ $t('Order') + ': ' }}
                </span>

                <label v-if="deliveryRequest._embedded && deliveryRequest._embedded.order"
                       @click="openOrder(deliveryRequest._embedded.order)" class="doc-link cursor-pointer">
                  {{ deliveryRequest._embedded.order.id }}
                </label>
              </p>
            </div>
            <div class="col">
              <q-input type="date" dense v-model="settings.default.sendDate"/>
            </div>
            <div class="col-2 text-right">
              <q-btn-dropdown
                  color="dark"
                  size="sm"
                  icon="picture_as_pdf"
                  :label="$t('Print')"
              >
                <q-list>
                  <q-item
                      v-close-popup
                      clickable
                      v-if="deliveryRequest?._embedded?.order"
                      @click="handlePrintOrderLabel"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Order label') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      @click="printLabelByRequest"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Request label') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      @click="handlePrint"
                      :disable="isLabelDisabled"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Print labels') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      :disable="isLabelDisabled"
                      @click="printLabel({ mode: 'show' })"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Show labels') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      :disable="!isAmazonLtl"
                      v-if="isAmazonLtl"
                      @click="printLabel({ mode: 'show', accept: 'application/boxes+pdf' })"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Amz boxes') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      :disable="!isAmazonLtl"
                      v-if="isAmazonLtl"
                      @click="printLabel({ mode: 'show', accept: 'application/pallets+pdf' })"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Amz pallets') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      :disable="!isAmazonLtl"
                      v-if="isAmazonLtl"
                      @click="printLabel({ mode: 'show', accept: 'application/bol+pdf' })"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Amz BOL') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      @click="handleSendWaiting"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Send to the waiting area') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>

                  <q-item
                      v-close-popup
                      clickable
                      @click="openAreYouSure"
                  >
                    <q-item-section>
                      <q-item-label>
                        {{ $t('Cancel Task') }}
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </div>

          <div class="row col-12 q-pa-sm border-bottom text-center"
               :class="{ 'bg-amber-6': deliveryRequest._embedded.sender.state !== 'blocked', 'bg-red-5': deliveryRequest._embedded.sender.state === 'blocked'}"
               v-if="deliveryRequest._embedded.sender.state === 'blocked'">
            <h6 class="q-ma-none text-subtitle1 text-bold">
              {{ $t('Sender status') + ':' }} {{ (deliveryRequest._embedded.sender.state) }}
            </h6>
          </div>

          <div class="row col-12 q-pa-sm text-center">
            <div class="col-7 border-right">
              <div
                class="row col-12 q-pa-sm q-mb-sm text-center"
              >
                <q-item-label class="text-h6 q-mb-sm text-left flot-left">
                  {{ $t('Shipment details') }}
                </q-item-label>
                <div class="float-left q-ml-lg">
                  <q-btn
                      color="primary"
                      text-color="white"
                      icon="add"
                      size="sm"
                      :label="$t('Go to next shipment')"
                      no-caps
                      @click="openCalculator"
                  />
                </div>
              </div>
              <div
                class="row col-12 q-pa-sm text-center"
              >
                <div class="row col-4">
                  <q-item-label class="text-h6 q-mb-sm full-width">
                    {{ $t('Ship to address') }}
                    <q-btn
                      color="positive"
                      text-color="white"
                      style="padding: 1px 6px;margin-left: 10px;"
                      icon="edit"
                      size="xs"
                      :label="$t('Edit')"
                      no-caps
                      @click="openEditor"
                    />
                  </q-item-label>

                  <q-item-label
                      lines="1"
                      class="text-caption full-width"
                  >
                    <div v-if="deliveryRequest?._embedded.recipient?.name">
                      {{ deliveryRequest._embedded.recipient.name || '' }}
                      {{ deliveryRequest._embedded.recipient.middleName ? ', ' + deliveryRequest._embedded.recipient.middleName : '' }}
                      {{ deliveryRequest._embedded.recipient.surname ? ', ' + deliveryRequest._embedded.recipient.surname : '' }}
                    </div>

                    <div v-else>
                      {{ $t('No recipient name') }}
                    </div>
                  </q-item-label>

                  <q-item-label
                      lines="1"
                      class="text-caption full-width"
                  >
                    <div v-if="deliveryRequest?._embedded.recipientAddress">
                      {{ deliveryRequest._embedded.recipientAddress.street || '' }}
                      {{ deliveryRequest._embedded.recipientLocality?.name ? ', ' + deliveryRequest._embedded.recipientLocality?.name : '' }}
                      {{ deliveryRequest._embedded.recipientAddress.postcode ? ', ' + deliveryRequest._embedded.recipientAddress.postcode : '' }}
                      {{ deliveryRequest._embedded.recipientAddress.country ? ', ' + deliveryRequest._embedded.recipientAddress.country : '' }}
                    </div>

                    <div v-else>
                      {{ $t('No recipient address') }}
                    </div>
                  </q-item-label>

                  <q-item-label
                      lines="1"
                      class="text-caption full-width"
                  >
                    <div v-if="deliveryRequest._embedded.recipient?.email">
                      {{ deliveryRequest._embedded.recipient.email }}
                    </div>

                    <div v-else>
                      {{ $t('No recipient email') }}
                    </div>
                  </q-item-label>

                  <q-item-label
                      class="text-caption full-width"
                      lines="1"
                  >
                    <div v-if="deliveryRequest._embedded.recipientPhone">
                      {{ deliveryRequest._embedded.recipientPhone.phone }}
                    </div>

                    <div v-else>
                      {{ $t('No recipient phone') }}
                    </div>
                  </q-item-label>
                </div>
                <div class="row col-4">
                  <q-item-label class="text-h6 q-mb-sm full-width">
                    {{ $t('Cost summary') }}
                    <q-btn
                        color="positive"
                        text-color="white"
                        style="padding: 1px 6px;margin-left: 10px;"
                        icon="edit"
                        size="xs"
                        :label="$t('Edit')"
                        no-caps
                        @click="openEditor"
                    />
                  </q-item-label>

                  <q-item-label
                      class="text-caption full-width"
                      lines="1"
                  >
                    {{ $t('Product') + ': ' + (deliveryRequest._embedded.order?.orderProducts?.length || 0)}}
                  </q-item-label>

                  <q-item-label
                      class="text-caption full-width"
                      lines="1"
                  >
                    {{ $t('Shipping') + ': ' + (deliveryRequest.retailPrice || 0)}}
                  </q-item-label>

                  <q-item-label
                      class="text-caption full-width"
                      lines="1"
                  >
                    {{ $t('Tax') + ': ' + (deliveryRequest.tax || 0)}}
                  </q-item-label>

                  <q-separator style="width: 50%;margin: 5px auto;" />

                  <q-item-label
                      class="text-caption full-width"
                      lines="1"
                  >
                    {{ $t('Total') + ': ' + this.handleFinalPrice()}}
                  </q-item-label>

                  <q-item-label
                      class="text-caption full-width"
                      lines="1"
                  >
                    {{ $t('Paid') + ': ' + this.handlePaidPrice()}}
                  </q-item-label>

                  <small v-if="task">
                    {{ $t('Task #') + ' ' + task.id }}
                    <q-badge outline color="primary">{{ task.state }}</q-badge>
                  </small>

                  <q-item-label
                      v-if="deliveryRequest._embedded.recipientLocality"
                      class="text-caption"
                      lines="5"
                  >

                  </q-item-label>
                </div>
                <div class="col-4">
                  <q-item-label class="text-h6">
                    {{ $t('Deliver by') }}
                  </q-item-label>

                  <q-input type="date" dense v-model="deliveryRequest.deliveryDate" style="width: 50%;margin: 0 auto 5px;"/>

                  <q-item-label class="text-h6">
                    {{ $t('Ship by') }}
                  </q-item-label>

                  <q-input type="date" dense v-model="settings.default.sendDate" style="width: 50%;margin: 0 auto 5px;"/>

                  <q-item-label class="text-h6 q-mb-sm">
                    {{ $t('Cash on delivery') }}
                  </q-item-label>

                  <q-input type="number" dense v-model="deliveryRequest.payment" style="width: 50%;margin: 0 auto 5px;"/>
                </div>
              </div>
            </div>
            <div class="col-5">
              <q-item-label class="text-h6 q-mb-sm">
                {{ $t('Sender') }}
              </q-item-label>

              <q-btn
                  color="primary"
                  text-color="white"
                  icon="calculate"
                  size="sm"
                  class="q-mr-xl q-my-xs float-right"
                  :label="$t('Rate calculator')"
                  no-caps
                  @click="openCalculator"
              />

              <q-item-label
                  lines="1"
                  class="text-caption text-left q-ml-xl"
              >
                <q-item-label class="text-h6 q-mb-sm">
                  {{ $t('Ship from') }}
                </q-item-label>
                {{ deliveryRequest._embedded.senderAddress.street || '-'}}
              </q-item-label>

              <q-item-label
                  lines="1"
                  class="text-caption"
              >
                <q-item-label class="text-h6 q-mb-sm">
                  {{ $t('Carrier name') }}
                </q-item-label>
                {{ deliveryRequest._embedded.deliveryService.name || '-'}}
              </q-item-label>

              <q-item-label
                  lines="1"
                  class="text-caption"
              >
                <q-item-label class="text-h6 q-mb-sm">
                  {{ $t('Parcel rate') }}
                </q-item-label>
                {{ deliveryRequest._embedded.rate.name || '-'}}
              </q-item-label>

              <q-btn
                  color="positive"
                  text-color="white"
                  icon="print"
                  class="q-my-lg"
                  size="lg"
                  :label="$t('Create and Print label')"
                  :disable="isCreateLabelEnabled"
                  no-caps
                  @click="handleSend(deliveryRequest)"
                  v-if="isCreateLabelButtonEnabled"
              />

              <q-item-label class="text-h6 q-mb-sm">
                {{ $t('Tracking number') }}
              </q-item-label>

              <q-input type="number" dense v-model="deliveryRequest.trackingNumber" style="width: 50%;margin: 0 auto 5px;"/>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="q-pa-md q-gutter-sm">
      <div class="row justify-center">
        <q-btn-toggle
            color="grey-7"
            no-caps
            toggle-color="dark"
            text-color="white"
            rounded
            v-model="slide"
            :disable="this.isLoading"
            :options="optionsList"
        />
      </div>
      <q-carousel
          v-if="deliveryRequest"
          v-model="slide"
          swipeable
          animated
          :padding="true"
          control-color="dark"
          class=" rounded-borders"
          style="height: fit-content"
      >
        <q-carousel-slide name="alpha">
          <q-separator/>

          <d-r-boxing :places="places" :places-stats="boxesStats" :place-items-pool="placeItemsPool"
                      :is-loading-finished="isFinished"
                      :scan-input="scanInput"
                      :disabled="handleDisabledFields"
                      @moved="handleBoxDimensions"
                      @handleRefreshBoxes="handleMoved"
                      @places-pool-change="handlePoolChange"/>
        </q-carousel-slide>

        <q-carousel-slide name="pallet">
          <q-separator/>

          <d-r-pallets :is-loading-finished="isFinished" :scan-input="scanInput"/>
        </q-carousel-slide>

        <q-carousel-slide name="deliveryRequest">

          <div class="row justify-center no-border q-py-sm" style="border: none !important;">
            <div class=" col-6 text-h5  block text-center q-no-border">
              <strong>{{ $t('Shipment') }}</strong>
            </div>

          </div>

          <q-separator/>

          <div v-if="deliveryRequest" class="row justify-center no-border" style="border: none !important;">

            <div class="col-5 block text-center q-no-border">
              <supplies-table-labelling
                  flat
                  :ext-filter="extFilter"
                  :deliveryRequest="deliveryRequest.id"
                  :services="isServices"
                  @on-dblclick="saveSupplies"
                  @change-catch-all="handleCatchAll"
                  @changeServices="handleChangeServices"
              />
            </div>

            <q-separator vertical/>

            <div class="col-6 block text-center q-no-border">
              <added-supplies-table
                  flat
                  :deliveryRequest="deliveryRequest.id"
                  :services="isServices"
                  @change-catch-all="handleCatchAll"
                  @changeServices="handleChangeServices"
              />
            </div>
          </div>
        </q-carousel-slide>
        <q-carousel-slide name="order">

          <div class="row justify-center no-border q-py-sm" style="border: none !important;">
            <div class=" col-6 text-h5  block text-center q-no-border">
              <strong>{{ $t('Order') }}</strong>
            </div>

          </div>

          <q-separator/>

          <div v-if="deliveryRequest && deliveryRequest._embedded && deliveryRequest._embedded.order"
               class="row justify-center no-border" style="border: none !important;">

            <div class="col-5 block text-center q-no-border">
              <supplies-table-labelling-order
                  flat
                  :ext-filter="extFilter"
                  :order="deliveryRequest._embedded.order.id"
                  :services="isServicesOrder"
                  @on-dblclick="saveOrderSupplies"
                  @change-catch-all="handleCatchAll"
                  @changeServices="handleChangeServicesOrder"
              />
            </div>

            <q-separator vertical/>

            <div class="col-6 block text-center q-no-border">
              <added-supplies-table-order
                  flat
                  :order="deliveryRequest._embedded.order.id"
                  :services="isServicesOrder"
                  @change-catch-all="handleCatchAll"
                  @changeServices="handleChangeServicesOrder"
              />
            </div>
          </div>
        </q-carousel-slide>
      </q-carousel>
    </div>
    <div>

    </div>

    <div
        class="fixed-bottom-right text-right"
        style="bottom: 10px; right: 20px;"
    >
      <q-btn
          v-if="deliveryRequest?._embedded?.order"
          class="q-mb-sm block q-ml-auto fab"
          fab
          size="lg"
          icon-right="output"
          color="light-blue-9"
          :label="$t('Order')"
          label-position="left"
          dark
          @click="handleOpenOrder(deliveryRequest?._embedded?.order)"
      />
    </div>

    <sticky-bottom-header
        :is-loading="isSaveLoading"
        :is-active="!window && hasDRChange"
        :disabled="isDisabledFields"
        @back="handleDiscard"
        @save="makeSaveDeliveryRequest"
    />


    <delivery-request-dimensions ref="exportModal"/>

    <printer-settings-modal
        ref="printerSettingsModal"
        @close="handlePrinterSettingsClose"
    />
    <q-dialog v-model="isOpenCalculator">
      <q-card style="min-width: 40vw; max-width: 60%">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('Calculator') }}
          </div>

          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
          />
        </q-card-section>

        <q-card-section>
          <d-s-calculator
              ref="calculator"
              :delivery-request="deliveryRequest"
              :locality="deliveryRequest?._embedded?.recipientLocality? deliveryRequest._embedded.recipientLocality: ''"
              :weight="Number(deliveryRequest.weight) ? deliveryRequest.weight : boxDimensions?.weight ? boxDimensions.weight : 1"
              :width="deliveryRequest.dimensions && deliveryRequest.dimensions.x ? deliveryRequest.dimensions.x : boxDimensions?.dimensions && boxDimensions.dimensions.x ? boxDimensions.dimensions.x : 1"
              :height="deliveryRequest.dimensions && deliveryRequest.dimensions.y ? deliveryRequest.dimensions.y : boxDimensions?.dimensions && boxDimensions.dimensions.y ? boxDimensions.dimensions.y : 1"
              :length="deliveryRequest.dimensions && deliveryRequest.dimensions.z ? deliveryRequest.dimensions.z : boxDimensions?.dimensions && boxDimensions.dimensions.z ? boxDimensions.dimensions.z : 1"
              @rate="updateRate"
              @update-dimensions="updateDimension"
          />
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="isOpenEditor">
      <q-card style="min-width: 40vw; max-width: 60%">
        <q-card-section class="row items-center q-pb-none">
          <div class="text-h6">
            {{ $t('Edit') }}
          </div>

          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
          />
        </q-card-section>

        <c-receiver
            :is-loading="!deliveryRequest"
            :disabled="isDisabledFields"
            :model="(deliveryRequest && deliveryRequest._embedded.recipient) || undefined"
            :phone-model="(deliveryRequest && deliveryRequest._embedded.recipientPhone) || undefined"
            :address-model="(deliveryRequest && deliveryRequest._embedded.recipientAddress) || undefined"
            :appOptions="appOptions"
            @profile="updateProfile"
            @phone="updatePhone"
            @address="updateRecipientAddress"
        />

      </q-card>
    </q-dialog>

    <q-dialog
        v-model="settingsModal"
        @hide="handleOpenCloseSettingsModal(true)"
    >
      <q-card style="min-width: 50vw;">
        <q-card-section class="row items-center q-pb-none">
          <q-space/>

          <q-btn
              v-close-popup
              icon="close"
              flat
              round
              dense
          />
        </q-card-section>

        <q-card-section class="q-pt-none">
          <packaging-settings @close="handleOpenCloseSettingsModal"/>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="areYouSure">
      <q-card style="max-width: 30vw">
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <div class="row justify-center items-center full-height text-center text-h6">
            {{ $t('Are You Sure?') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <q-card-section class="text-center">

            <q-btn
                textarea="center"
                color="light-blue-9"
                class="q-mr-sm"
                text-color="white"
                :label="$t('Cancel task')"
                @click="handleCancelTask"
            />

            <q-btn
                textarea="center"
                :label="$t('Discard')"
                @click="closeAreYouSure"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>

    <q-dialog v-model="noSizesCheck">
      <q-card style="max-width: 30vw">
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <div class="row justify-center items-center full-height text-center text-h6 " textarea="center">
            {{ $t('Are You Sure?') }}
          </div>

          <q-space/>

          <q-btn v-close-popup icon="close" flat round dense/>
        </q-card-section>
        <q-card-section class="row items-center q-pb-none">
          <div>
            {{ $t('You do not have sizes do you want to proceed with default ones ?') }}
          </div>
          <div>
            {{ `${$t('Weight')}: ${$t(this.settings.default.weight)} (${$t(appOptions.defaultDimensions.weightUnit)})` }}
            <br>
            {{ `${$t('Width')}: ${$t(this.settings.default.width)} (${$t(appOptions.defaultDimensions.dimensionUnit)})` }}
            <br>
            {{ `${$t('Height')}: ${$t(this.settings.default.height)} (${$t(appOptions.defaultDimensions.dimensionUnit)})` }}
            <br>
            {{ `${$t('Length')}: ${$t(this.settings.default.length)} (${$t(appOptions.defaultDimensions.dimensionUnit)})` }}
          </div>
        </q-card-section>
        <q-card-section class="row justify-center items-center full-height text-center text-h6">
          <q-card-section class="text-center">

            <q-btn
                textarea="center"
                color="light-blue-9"
                class="q-mr-sm"
                text-color="white"
                :label="$t('Use Default')"
                @click="useDefaultSettings"
            />

            <q-btn
                textarea="center"
                :label="$t('Discard')"
                @click="noSizesCheck = false"
            />
          </q-card-section>
        </q-card-section>
      </q-card>
    </q-dialog>

    <filter-modal
        ref="filterModal"
        :filter-fields="filterFields"
        @submit="handleConsumptionFilter"
    />

    <delivery-request-services-modal ref="deliveryRequestModal"/>
    <delivery-request-services-modal-side ref="deliveryRequestModalSide"/>

    <labelling-movement-modal ref="labellingMovement" @change="handleItemSubmit" @submit="handleMoved"/>

    <create-item-modal ref="createItemModal" @submit="handleSubmitWaiting"/>

    <confirm-modal ref="confirmModal"/>
  </div>
</template>

<script>
// Vuex
import { mapActions, mapGetters, mapMutations } from 'vuex'

// Components
import BarcodeInput from './../../components/barcode-input/BarcodeInput'
import PackagingSettings from './../../components/packaging-settings/PackagingSettings'
import DeliveryRequestsList from './../../components/delivery-requests-list/DRList'
import ConfirmModal from '../../components/confirm-modal/ConfirmModal.vue'
import PrinterSettingsModal from '../../components/modals/PrinterSettingsModal.vue'
import FilterModal from '../../components/modals/FilterModal.vue'
import { HALApiService } from '@/apps/app/services'
import { defineAsyncComponent } from 'vue'
import { doCopy } from './../../utils/copy-utils'
import DeliveryRequestServicesModal from '../../components/modals/DeliveryRequestServicesModal.vue'
import DeliveryRequestServicesModalSide from '../../components/modals/DelveryRequestServicesModalSide.vue'
import DRBoxing from '@/apps/app/components/delivery-requests/DRBoxing.vue'
import SuppliesTableLabelling from '@/apps/app/components/tables/SuppliesTableLabelling.vue'
import AddedSuppliesTable from '@/apps/app/components/tables/AddedSuppliesTable.vue'
import SuppliesTableLabellingOrder from '@/apps/app/components/tables/SuppliesTableLablellingOrder.vue'
import AddedSuppliesTableOrder from '@/apps/app/components/tables/AddedSuppliesTableOrder.vue'
import LabellingMovementModal from '@/apps/app/components/modals/LabellingMovementModal.vue'
import MeasuringRow from '@/apps/app/components/products/MeasuringRow.vue'
import FiltersCollapse from '@/apps/app/components/filters/FilterCollapse.vue'
import deliveryRequestStatusMatrix from '@/apps/app/config/DeliveryRequestMatrix'
import DRPallets from '@/apps/app/components/delivery-requests/DRPallets.vue'
import { transliterate as tr } from 'transliteration'
import CreateItemModal from '@/apps/app/components/modals/CreateItemModal.vue'
import ActionHeader from '../../components/action-header/ActionHeader'
import History from './../../components/history/history'
import DeliveryRequestDimensions from '../../components/delivery-services/DeliveryRequestDimensions'
import BaseAddress from './../../components/clients/BaseAddress'
import DeliveryRequestForm from './../../components/clients/DeliveryRequestForm'
import ChatModal from '../../components/chat/ChatModal'
import DRProductsModal from '../../components/modals/DRProductsModal.vue'
import DocumentTemplateModal from '../../components/modals/DocumentTemplateModal.vue'
import TrackingNumberModal from '../../components/modals/TrackingNumberModal.vue'
import ShipmentTaskModal from '../../components/modals/ShipmentTaskModal.vue'
import EavModal from '../../components/modals/EavModal.vue'

// Mixins
import TableMixin from '../../components/global/TableMixin'

// Helpers
import {getRouteOptions} from "@/apps/app/helpers/helpers"
import _ from "lodash";

// Used to generate place package with sizes
function createPlaceSize (place) {
  return {
    id: place?.id,
    weight: null,
    dimensions: {
      x: null,
      y: null,
      z: null
    }
  }
}

/**
 * Create Date
 *
 * @param {object} date
 *
 * @returns {String}
 */
function createDate (date) {
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()

  return `${year}-${month < 10 ? `0${month}` : month}-${day < 10 ? `0${day}` : day}`
}

export default {
  name: 'DeliveryRequest',
  emits: ['close', 'submit', 'finish-executing'],
  components: {
    DeliveryRequestServicesModal,
    DeliveryRequestDimensions,
    ActionHeader,
    CProfile: defineAsyncComponent(() => import('./../../components/clients/Profile.vue')),
    CAddress: defineAsyncComponent(() => import('../../components/clients/Address.vue')),
    DSCalculator: defineAsyncComponent(() => import('../../components/delivery-services/Calculator.vue')),
    CReceiver: defineAsyncComponent(() => import('./../../components/clients/Receiver.vue')),
    CreateItemModal,
    DRPallets,
    DRBoxing,
    FiltersCollapse,
    MeasuringRow,
    LabellingMovementModal,
    AddedSuppliesTableOrder,
    SuppliesTableLabellingOrder,
    AddedSuppliesTable,
    SuppliesTableLabelling,
    DeliveryRequestServicesModalSide,
    BarcodeInput,
    PackagingSettings,
    DeliveryRequestsList,
    ConfirmModal,
    PrinterSettingsModal,
    FilterModal,
    History,
    BaseAddress,
    DeliveryRequestForm,
    ChatModal,
    DRProductsModal,
    DocumentTemplateModal,
    TrackingNumberModal,
    ShipmentTaskModal,
    EavModal
  },
  mixins: [
    TableMixin
  ],
  props: {
    noHeader: {
      type: Boolean,
      default () {
        return false
      }
    },
    window: {
      type: Boolean,
      default () {
        return false
      }
    },
    outsideDeliveryRequest: {
      type: Object,
      default () {
        return null
      }
    }
  },
  data () {
    return {
      rates: [],
      slide: 'alpha',
      isLoadingFinished: false,
      services: [],
      servicesOrder: [],
      extFilter: [],
      isBeta: false,
      carrier: null,
      settingsModal: false,
      isFocused: false,
      loadedConsumptions: [],
      isFirstAction: true,
      isMultiPlaceGood: true,
      placesToUse: [],
      placeItemsPool: null,
      indexOfSelected: 0,
      selectedPlace: null,
      placeSize: createPlaceSize(),
      placeSizes: [],
      boxesStats: {
        count: 1,
        page: 1,
        total: 1,
      },
      isLoad: false,
      isMeasured: true,
      printerSub: null,
      consumptionFilter: [],
      catchAll: true,
      isOpenCalculator: false,
      isOpenEditor: false,
      areYouSure: false,
      isServicesOpen: false,
      measuredDR: [],
      loadedConsumptionsData: {},
      deliveryRequests: {
        items: [],
        page: 0,
        totalPages: 1,
        totalItems: 0
      },
      filter: [
        { field: 'state', type: 'in', values: ['pre_processing', 'error', 'pending'] },
        { field: 'sendDate', type: 'gte', value: createDate(new Date()) }
      ],
      filterFields: [
        'sender',
        'created.from',
        'created.to',
        'deliveryService'
      ],
      alreadyLoaded: false,
      isLoading: false,
      isLabelGenerated: false,
      deliveryStatuses: deliveryRequestStatusMatrix,
      selectedPlaceWithFlag: false,
      currentConsumptionFilter: null,
      validStatusCodes: [503, 409, 423],
      secondsSinceTimestamp: null,
      taskCreateUnlockStates: ['upload_error', 'error', 'cancel'],
      scanInput: null,
      step: 1,
      openDropdown: false,
      doneStep: false,
      noSizesCheck: false,
      boxDimensions: null,
      beta: 'amber-9',
    }
  },
  computed: {
    ...mapGetters([
      'appOptions',
      'settings',
      'task',
      'queue',
      'timestamp',
      'mode',
      'deliveryRequest',
      'places',
      'deliveryRequestsItemsNumber',
      'consumptions',
      'consumptionsPage',
      'consumptionsTotalPages',
      'lockedDeliveryRequests',
      'width',
      'height',
      'length',
      'weight',
      'deliveryServices',
      'rates',
      'history',
      'loadingNow',
      'servicePoints',
      'totalServicePointsNumber',
      'senders',
      'servicePointsPage',
      'servicePointsTotalPages',
      'ratesPage',
      'ratesTotalPages',
      'deliveryRequests',
      'hasDRChange',
      'hasBaseAccess',
      'routeShortcuts'
    ]),
    note () {
      if (this.routeShortcuts.find(({ route }) => route === this.$route.fullPath)) {
        return {
          color: 'positive',
          label: 'Unpin',
          icon: 'push_pin'
        }
      }
      return {
        color: 'dark',
        label: 'Pin',
        icon: 'push_pin'
      }
    },
    schema () {
      return {
        groups: [
          {
            styleClasses: 'row',
            fields: [
              {
                type: 'input',
                value: this.deliveryRequest.extId,
                label: this.$t('Ext ID'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                onChange: (extId) => {
                  this.updateDeliveryRequest({ extId })
                }
              }
            ]
          }
        ]
      }
    },
    page () {
      return {
        id: this.deliveryRequest && this.deliveryRequest.id,
        name: this.deliveryRequest && this.deliveryRequest.id
            ? this.$t('Shipment')
            : this.$t('New shipment')
      }
    },
    addressType () {
      let rightTab = this.simple ? 'domestic' : 'international'
      let leftTab = this.simple ? 'international' : 'domestic'

      return this.isInternational
          ? rightTab
          : leftTab
    },
    locality () {
      return this.deliveryRequest && this.deliveryRequest._embedded.recipientAddress._embedded.locality
    },
    outSideFilters () {
      return [
        {
          type: 'in',
          field: 'deliveryService',
          values: this.deliveryServicesIds
        }
      ]
    },
    calculatorError () {
      if (!this.deliveryRequest) {
        return 'Shipping Request data is required!'
      }

      return ''
    },
    headerModel () {
      if (!this.deliveryRequest) {
        return []
      }

      const option = {
        id: 'deliveryStatuses',
        type: 'super-dropdown',
        variant: 'link',
        color: 'default',
        editableFields: false,
        onClick: (state) => {
          this.updateDeliveryRequest({ state })

          if (this.deliveryRequest.id) {
            this.saveDeliveryRequestByPayload({ data: { state }, id: this.deliveryRequest.id })
          }
        },
        value: { id: 'pending_queued', title: 'Pending Queued' },
        options: this.deliveryStatuses
      }

      const dropdown = {
        section: 'Dropdown',
        className: 'col-12 col-sm-2 text-right q-pb-sm',
        options: [
          option
        ]
      }

      if (this.deliveryRequest && this.deliveryRequest.state !== option.value.id) {
        this.deliveryStatuses.find(group => {
          let status = group.buttons.find(btn => {
            return btn.id === this.deliveryRequest.state
          })

          if (status) {
            option.color = group.color
            option.value = status
          }

          return status
        })
      }

      let deliveryService = this.deliveryRequest &&
          this.deliveryRequest.id &&
          this.deliveryRequest._embedded &&
          this.deliveryRequest._embedded.deliveryService &&
          this.deliveryRequest._embedded.deliveryService.id

      if (!deliveryService && this.deliveryRequest.id && this.deliveryRequest._embedded.rate) {
        const parts = this.deliveryRequest._embedded.rate._embedded.deliveryService._links.self.href.split('/')
        deliveryService = Number(parts[parts.length - 1])
      }

      const print = (this.appOptions.multiprintService || []).includes(deliveryService)
          ? {
            id: 'print-dropdown',
            wrapperClassName: 'col-3 q-px-xs',
            wrapperStyle: 'max-width: 100px;',
            type: 'dropdown',
            variant: 'primary',
            label: this.$t('Print'),
            options: [
              {
                id: 'print-label',
                type: 'button',
                icon: 'print',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Print label'),
                onClick: this.printLabelByRequest
              },
              {
                id: 'print-a5',
                type: 'button',
                icon: 'print',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('A5'),
                onClick: () => {
                  this.printLabelByRequestBySize({ pageSize: 'A5' })
                }
              },
              {
                id: 'print-a6',
                type: 'button',
                icon: 'print',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('A6'),
                onClick: () => {
                  this.printLabelByRequestBySize({ pageSize: 'A6' })
                }
              }
            ]
          }
          : {
            id: 'print',
            wrapperClassName: 'col-3 q-px-xs',
            wrapperStyle: 'max-width: 100px;',
            type: 'button',
            icon: 'print',
            variant: 'light',
            disabled: !this.deliveryRequest || !this.deliveryRequest.id,
            label: this.$t('Print label'),
            onClick: () => {
              this.printLabelByRequest()
            }
          }

      const actions = {
        section: 'Actions',
        className: 'col-12 col-sm text-center row items-center q-gutter-y-xs q-py-xs no-drop',
        options: [
          {
            id: 'services-dropdown',
            type: 'dropdown',
            label: '•••',
            options: [
              print,
              {
                id: 'calculator',
                type: 'button',
                icon: 'calculate',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Calculator'),
                onClick: this.handleOpenModal
              },
              {
                if: this.hasBaseAccess,
                id: 'export',
                type: 'button',
                icon: 'import_export',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !['pre_processing', 'pending'].includes(this.deliveryRequest.state),
                label: this.$t('Create Label'),
                onClick: this.handleExport
              },
              {
                id: 'task',
                icon: 'task',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Shipping task'),
                onClick: () => {
                  const query = {
                    per_page: 25,
                    filter: [
                      { field: 'deliveryRequest', type: 'eq', value: this.deliveryRequest.id },
                    ]
                  }

                  this.loadPreprocessingTaskQuery(query)
                    .then((task) => {
                      if(task.items) {
                        this.$refs.shipmentTaskModal.open(task.items[0])
                      }
                      this.isRequestedTask = true
                    })
                }
              },
              {
                id: 'trackingsNumber',
                type: 'button',
                variant: 'light',
                icon: 'gps_not_fixed',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !!this.deliveryRequest.trackingNumber,
                label: this.$t('Tracking number'),
                onClick: () => {
                  this.$refs.trackingNumberModal.show(this.deliveryRequest)
                }
              },
              {
                id: 'services',
                type: 'button',
                variant: 'light',
                icon: 'inventory_2',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Services/Upload PDF'),
                onClick: () => {
                  const defaultData = {
                    state: 'active',
                    deliveryRequest: this.deliveryRequest.id,
                  }

                  this.$refs.deliveryRequestModal.open(this.deliveryRequest.id, defaultData)
                  this.catchAll = false
                }
              },
              {
                id: 'order',
                type: 'button',
                icon: 'sell',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.deliveryRequest.eav || !this.deliveryRequest.eav['delivery-request-products-order'],
                variant: 'light',
                label: this.$t('Order'),
                onClick: () => {
                  this.$router.push(`${this.prefix}/orders/entity/unknown/${this.deliveryRequest.eav['delivery-request-products-order']}`)
                }
              },
              {
                id: 'places',
                type: 'button',
                icon: 'store',
                variant: 'light',
                label: this.$t('Products/Places'),
                onClick: this.showPlaces
              },
              {
                id: 'eav',
                icon: 'receipt_long',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.deliveryRequest.eav,
                label: this.$t('EAV'),
                onClick: () => {
                  const options = {
                    entityClass: this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest
                  }

                  this.$refs.eavModal.open(this.deliveryRequest.eav, options)
                }
              },
              {
                id: 'services',
                type: 'button',
                variant: 'light',
                icon: 'inventory_2',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Services'),
                onClick: () => {
                  const defaultData = {
                    state: 'active',
                    deliveryRequest: this.deliveryRequest.id,
                  }

                  this.$refs.deliveryRequestModal.open(this.deliveryRequest.id, defaultData)
                  this.catchAll = false
                }
              },
              {
                id: 'history',
                type: 'button',
                icon: 'history',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('History'),
                onClick: this.handleHistory
              },
              {
                id: 'chat',
                type: 'button',
                icon: 'comments',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Chat'),
                onClick: () => {
                  const entities = [
                    {
                      entityClass: 'Orderadmin\\DeliveryServices\\Entity\\DeliveryRequest',
                      entity: this.deliveryRequest.id
                    }
                  ]

                  this.$refs.chatModal.open(entities)
                }
              },
              {
                id: 'template',
                icon: 'receipt_long',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Documents'),
                onClick: this.handleGetDocument
              },
              {
                id: 'lock',
                icon: this.isLockedDR ? 'lock-open' : 'lock',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.isLockedDR,
                label: this.$t(this.isLockedDR ? 'Unlock' : 'Lock'),
                onClick: this.handleUnlock
              }
            ]
          },
          {
            id: 'beta',
            wrapperStyle: 'max-width: 60px;',
            style: 'font-size: 10px!important;font-weight: bold;padding: 0;',
            wrapperClassName: 'col-4 q-px-sm beta',
            type: 'button',
            label: 'β',
            color: this.beta,
            size: 'sm',
            onClick: () => {
              this.$router.push(`/outbound/shipments/entity/${this.deliveryRequest.id}`)
            }
          }
        ]
      }

      const actionsSimple = {
        section: 'Actions',
        className: 'col-12 col-sm row q-pb-xs no-drop',
        options: [
          {
            id: 'services-dropdown',
            type: 'dropdown',
            label: '•••',
            options: [
              print,
              {
                id: 'calculator',
                type: 'button',
                icon: 'calculate',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Calculator'),
                onClick: this.handleOpenModal
              },
              {
                if: this.hasBaseAccess,
                id: 'export',
                type: 'button',
                icon: 'import_export',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !['pre_processing', 'pending'].includes(this.deliveryRequest.state),
                label: this.$t('Create Label'),
                onClick: this.handleExport
              },
              {
                id: 'task',
                icon: 'task',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Shipping task'),
                onClick: () => {
                  const query = {
                    per_page: 25,
                    filter: [
                      { field: 'deliveryRequest', type: 'eq', value: this.deliveryRequest.id },
                    ]
                  }

                  this.loadPreprocessingTaskQuery(query)
                    .then((task) => {
                      if(task.items) {
                        this.$refs.shipmentTaskModal.open(task.items[0])
                      }
                      this.isRequestedTask = true
                    })
                }
              },
              {
                id: 'trackingsNumber',
                type: 'button',
                variant: 'light',
                icon: 'gps_not_fixed',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !!this.deliveryRequest.trackingNumber,
                label: this.$t('Tracking number'),
                onClick: () => {
                  this.$refs.trackingNumberModal.show(this.deliveryRequest)
                }
              },
              {
                id: 'services',
                type: 'button',
                variant: 'light',
                icon: 'inventory_2',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Services/Upload PDF'),
                onClick: () => {
                  const defaultData = {
                    state: 'active',
                    deliveryRequest: this.deliveryRequest.id,
                  }

                  this.$refs.deliveryRequestModal.open(this.deliveryRequest.id, defaultData)
                  this.catchAll = false
                }
              },
              {
                id: 'order',
                type: 'button',
                icon: 'sell',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.deliveryRequest.eav || !this.deliveryRequest.eav['delivery-request-products-order'],
                variant: 'light',
                label: this.$t('Order'),
                onClick: () => {
                  this.$router.push(`${this.prefix}/orders/entity/unknown/${this.deliveryRequest.eav['delivery-request-products-order']}`)
                }
              },
              {
                id: 'places',
                type: 'button',
                icon: 'store',
                variant: 'light',
                label: this.$t('Products/Places'),
                onClick: this.showPlaces
              },
              {
                id: 'eav',
                icon: 'receipt_long',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.deliveryRequest.eav,
                label: this.$t('EAV'),
                onClick: () => {
                  const options = {
                    entityClass: this.$entities.Orderadmin_DeliveryServices_Entity_DeliveryRequest
                  }

                  this.$refs.eavModal.open(this.deliveryRequest.eav, options)
                }
              },
              {
                id: 'services',
                type: 'button',
                variant: 'light',
                icon: 'inventory_2',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Services'),
                onClick: () => {
                  const defaultData = {
                    state: 'active',
                    deliveryRequest: this.deliveryRequest.id,
                  }

                  this.$refs.deliveryRequestModal.open(this.deliveryRequest.id, defaultData)
                  this.catchAll = false
                }
              },
              {
                id: 'history',
                type: 'button',
                icon: 'history',
                variant: 'light',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('History'),
                onClick: this.handleHistory
              },
              {
                id: 'template',
                icon: 'receipt_long',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id,
                label: this.$t('Documents'),
                onClick: this.handleGetDocument
              },
              {
                id: 'lock',
                icon: this.isLockedDR ? 'lock-open' : 'lock',
                disabled: !this.deliveryRequest || !this.deliveryRequest.id || !this.isLockedDR,
                label: this.$t(this.isLockedDR ? 'Unlock' : 'Lock'),
                onClick: this.handleUnlock
              }
            ]
          },
          {
            id: 'beta',
            wrapperStyle: 'max-width: 60px;',
            style: 'font-size: 10px!important;font-weight: bold;padding: 0;',
            wrapperClassName: 'col-4 q-px-sm beta',
            type: 'button',
            label: 'β',
            color: this.beta,
            size: 'sm',
            onClick: () => {
              this.$router.push(`/shipments/entity/${this.deliveryRequest.id}`)
            }
          }
        ]
      }

      const pin = {
        section: 'LockPin',
        className: 'col-12 col-sm-1',
        style: 'max-width: 80px;',
        options: [
          {
            id: 'pin',
            wrapperStyle: 'max-width: 60px;',
            wrapperClassName: 'col-4 q-px-xs',
            type: 'button',
            icon: this.note.icon,
            color: this.note.color,
            size: 'sm',
            onClick : () => {
              const action = this.routeShortcuts.find(({ route }) => route === this.$route.fullPath)
                ? 'removeRouteToShortcuts'
                : 'addRouteToShortcuts'

              const data = {
                route: this.$route.fullPath,
                name: this.page.id,
                description: this.page.name
              }

              this[action](data)
            }
          }
        ]
      }

      return [
        {
          section: 'BackAction',
          className: 'hide-on-mobile',
          options: [
            {
              id: 'back',
              type: 'button',
              icon: 'arrow_back',
              variant: 'light',
              style: 'white-space: nowrap;',
              label: this.$t('Back'),
              onClick: this.handleBack
            }
          ]
        },
        {
          section: 'Title',
          className: 'q-pa-sm fit--mobile text-min-content',
          options: [
            {
              id: 'title',
              type: 'text',
              className: 'text-subtitle1 row justify-center',
              valueClassName: 'col-6 col-sm-12 text-right--mobile q-px-xs',
              valueStyle: 'white-space: nowrap',
              additionalValueClassName: 'col-6 col-sm-12 q-px-xs',
              value: this.header,
              additionalValue: this.deliveryRequest && this.deliveryRequest.trackingNumber ? this.$t('#') + this.deliveryRequest.trackingNumber : null
            }
          ]
        },
        !this.simple ? actions : actionsSimple,
        dropdown,
        pin
      ]
    },
    isLockedDR () {
      return this.lockedDeliveryRequests.includes(this.deliveryRequest && this.deliveryRequest.id)
    },
    isDisabledFields () {
      let currentStateOptions = this.deliveryStatuses[0].buttons[0]

      for (let i = 0; i < this.deliveryStatuses.length; i++) {
        const group = this.deliveryStatuses[i]
        const state = group.buttons.find(btn => btn.id === (this.deliveryRequest || {}).state)

        if (state) {
          currentStateOptions = state
          break
        }
      }

      if (currentStateOptions.id === 'pending_queued') {
        if (!this.deliveryRequest || !this.deliveryRequest.id) {
          return false
        }

        return !currentStateOptions.editableField
      }

      return !currentStateOptions.editableField || this.isLockedDR
    },
    isCreateLabelEnabled () {
      if (this.isLoading || !this.isCreateLabelButtonEnabled || this.ifCreateDisabled || ((!this.places || this.places.length === 0) && this.isFinished)) {
        return true
      }
      return false
    },
    isFinished () {
      return this.isLoadingFinished
    },
    isServices () {
      return this.services
    },
    isServicesOrder () {
      return this.servicesOrder
    },
    ifChecklist () {
      if (this.deliveryRequest?._embedded?.source?.handler === 'amazon') {
        return true
      }
      return true
    },
    ifCreateDisabled () {
      if (this.places) {
        const sizes = this.calculateOverallSizes(this.places)

        if (this.queue?.settings && this.queue?.settings['trust-incoming-measurements']) {
          if (!sizes.dimensions.z || !sizes.dimensions.x || !sizes.dimensions.y || !sizes.weight) {
            return true
          }
        }
      }

      return false
    },
    isCreateLabelButtonEnabled () {
      if (!this.task || this.taskCreateUnlockStates.includes(this.task?.state)) {
        return true
      }

      if (this.isLabelGenerated && this.secondsSinceTimestamp > 90) {
        return true
      }

      if (!this.isLabelDisabled) {
        return false
      }

      return !this.isLabelGenerated
    },
    ifConsumptionDisabled () {
      if (!this.deliveryRequest || !!this.deliveryRequest.sentBy) {
        return true
      }

      return false
    },
    schema3 () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            id: 'drForm',
            styleClasses: 'row',
            fields: [
              {
                ref: 'rate',
                type: 'multiselect',
                label: this.$t('Shipping rate'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'rate',
                value: this.deliveryRequest && this.deliveryRequest._embedded.rate,
                required: true,
                disabled: !this.deliveryRequest ||
                    !this.deliveryRequest._embedded.sender,
                customLabel (row) {
                  if (row && typeof row === 'object') {
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row._embedded?.deliveryService?.logo) {
                    return ''
                  }

                  return `${this.appOptions.defaultServer}${row._embedded.deliveryService.logo}`
                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  query['order-by'] = [
                    { type: 'field', field: 'deliveryService', direction: 'desc' },
                    { type: 'field', field: 'name', direction: 'desc' }
                  ]

                  if (this.deliveryRequest._embedded.deliveryService) {
                    query.filter.push({
                      type: 'eq',
                      field: 'deliveryServices',
                      value: this.deliveryRequest._embedded.deliveryService.id
                    })
                  } else if (this.isEnabledSenderFilter && this.deliveryServicesIDs && this.deliveryServicesIDs.length > 0) {
                    query.filter.push({
                      type: 'in',
                      field: 'deliveryServices',
                      values: this.deliveryServicesIDs
                    })
                  }

                  return this.$service.deliveryServiceRate.getAll(query)
                },
                onChange: (rate) => {
                  const types = ['service_point', 'self_service_point', 'simple']
                  this.isDisabledServicePoints = !types.includes((rate || {}).type)

                  this.updateDeliveryRequestEmbedded({ rate, servicePoint: null })
                  this.saveDeliveryRequest()
                }
              },
            ]
          },
        ]
      }
    },
    schema2 () {
      return {
        isLoading: this.isLoading,
        groups: [
          {
            id: 'drForm',
            styleClasses: 'row',
            fields: [
              {
                ref: 'deliveryService',
                type: 'multiselect',
                label: this.$t('Shipping carrier'),
                wrapperStyleClasses: 'col-12 q-pa-xs',
                field: 'deliveryService',
                value: this.deliveryRequest && this.deliveryRequest._embedded.deliveryService,
                required: true,
                disabled: !this.deliveryRequest || !this.deliveryRequest._embedded.sender,
                customLabel (row) {
                  let ratePrice = 0
                  if (row && typeof row === 'object') {
                    this.rates.forEach(e => {
                      if (e.id === row.id) {
                        ratePrice = e.deliveryPrice
                      }
                    })
                    if (ratePrice) {
                      return `${row.name} (${row.id})  Price: ${ratePrice}`
                    }
                    return `${row.name} (${row.id})`
                  }

                  return row
                },
                imagePreview: (row) => {
                  if (!row || !row.logo) {
                    return ''
                  }
                  if (row.logo.includes('http')) {
                    return `${row.logo}`
                  } else {
                    return `${this.appOptions.defaultServer}${row.logo}`
                  }

                },
                onScroll: (search, page) => {
                  const query = {
                    per_page: 25,
                    page,
                    search,
                    'order-by': [
                      { type: 'field', field: 'name', direction: 'desc' }
                    ],
                    filter: [
                      { type: 'eq', field: 'state', value: 'active' }
                    ]
                  }

                  if (this.country && this.isEnabledSenderFilter) {
                    query.filter.push({ type: 'eq', field: 'country', value: this.country })
                  }

                  return this.$service.deliveryService.getAll(query)
                },
                onChange: (deliveryService) => {
                  this.carrier = deliveryService
                  this.updateDeliveryRequestEmbedded({ deliveryService, rate: null, servicePoint: null })
                  this.saveDeliveryRequest()
                },
              }
            ]
          },
        ]
      }
    },
    isLabelDisabled () {
      if (this.deliveryRequest._embedded.rate.type === 'ltl' && this.task?.state === 'measuring') {
        return false
      }

      return this.task?.state != 'closed'
    },
    isAmazonLtl () {
      if (this.deliveryRequest._embedded.rate.type === 'ltl' && this.deliveryRequest?._embedded?.source?.handler === 'amazon') {
        return true
      }

      return false
    },
    handleDisabledFields () {
      if (this.queue?.settings && this.queue.settings['disable-packing-interface']) {
        return true
      }
      return false
    },
    optionsList () {
      let options = [
        { label: 'Labeling', value: 'alpha' },
        { label: 'Order Services', value: 'order' },
        { label: 'Shipment Services', value: 'deliveryRequest' },
        { label: 'Notes', value: 'notes' },
        { label: 'Customer Communication', value: 'customerCommunication' },
        { label: 'Ship History', value: 'shipHistory' }
      ]

      if (this.deliveryRequest?._embedded?.rate?.type === 'ltl') {
        const index = options.findIndex(option => option.value === 'alpha')

        options.splice(index + 1, 0, { label: 'LTL/FTL', value: 'pallet' })
      }

      return options
    }
  },
  watch: {
    // Check for invalid value
    slide (newSlide) {
      // Check if the active slide is the "order" slide
      if (newSlide === 'order') {
        this.servicesOrder = this.servicesOrder
      }
      if (newSlide = 'deliveryRequest') {
        this.services = this.services
      }
    },
    consumptions () {
      this.handleChange('consumptions')
    },
    queue (newValue) {
      if (newValue && newValue.settings && newValue.settings['load-requests-automatically'] && !this.alreadyLoaded) {
        this.loadDeliveryRequests(true)
        this.alreadyLoaded = true
      }

      if (this.$route.params.deliveryRequest && !this.isLoading && (!this.deliveryRequest || parseInt(this.deliveryRequest.id) !== parseInt(this.$route.params.deliveryRequest))) {
        this.handleBarcode({ value: this.$route.params.deliveryRequest, type: 'DS/DR' })
      }
    },
    deliveryRequest: {
      handler (newValue, oldValue) {
        if (newValue && (!oldValue || newValue.id !== oldValue.id)) {
          if (this.deliveryRequest && this.queue?.settings && this.queue.settings['generate-shipping-manifest']) {
            this.autoGenerateManifest()
          }
        }

        if (newValue && newValue._embedded && newValue._embedded.rate) {
          const types = ['service_point', 'self_service_point']
          this.isCourier = !types.includes(newValue._embedded.rate.type)
        }

        if (newValue) {
          if (newValue.places && Array.isArray(newValue.places)) {
            let places = _.cloneDeep(newValue.places)
            this.isLoadingFinished = true
            this.setPlaces(Object.values(places))
          } else {
            this.handleMoved()
          }
        }

        this.servicesOrder = []
        this.services = []
      },
      deep: true
    },
    task (taskValue) {
      let states = ['closed', 'measuring']
      let combinedStates = [...states, ...this.taskCreateUnlockStates]
      this.checkTaskState()
      if (taskValue && combinedStates.includes(this.task.state)) {
        this.isLabelGenerated = false
      } else {
        this.isLabelGenerated = true
      }

      setInterval(() => { this.updateTimeDifference() }, 1000)
    }
  },
  beforeMount() {
    if (this.deliveryRequest && this.deliveryRequest.id) {
      this.header = this.$t('Shipments');
      this.header += (' / <tlabel>#</tlabel>' + this.deliveryRequest.id)
      if (this.deliveryRequest.extId) {
        this.header += ' (<tlabel>ExtId</tlabel>' + this.deliveryRequest.extId + ')'
      }
    } else {
      this.header = this.$t('New shipment')
    }
  },
  mounted () {
    this.setHasDRChange(false)
    if (this.deliveryRequest && this.window) {
      this.isCourier = (this.deliveryRequest._embedded.rate && this.deliveryRequest._embedded.rate.type === 'simple') || !this.deliveryRequest._embedded.servicePoint
      return
    }

    if (!this.deliveryRequest && this.$route.params.id) {
      this.loadDeliveryRequest(this.$route.params.id)
          .then(() => {
            this.setHasDRChange(false)
            this.submitOpenRequest()
          })
    }

    if (this.deliveryRequest && this.$route.params.id) {
      this.submitOpenRequest()
    }

    if (!this.$route.params.id) {
      this.setNewDeliveryRequest()
    }

    if (this.deliveryRequest) {
      this.isCourier = (this.deliveryRequest._embedded.rate && this.deliveryRequest._embedded.rate.type === 'simple') || !this.deliveryRequest._embedded.servicePoint
    }
    this.isDisabledDomestic()

    if (this.appOptions.versionType === 'simple') {
      this.simple = true
    } else {
      this.prefix = '/outbound'
    }
    if (!this.queue && this.$route.params.id) {
      const query = {}

      if (this.$route.params.id && parseInt(this.$route.params.id) !== 0) {
        query.filter = [{ type: 'eq', field: 'id', value: this.$route.params.id }]
      }

      return this.$service.preprocessingQueue.getAll(query)
          .then(({ items }) => {
            this.queues = items

            if (items[0]) {
              this.setQueue(items[0])

              this.updateSettings(this.kebabToCamel(this.queue.settings))
            }
          })
    } else if (this.$route.params.deliveryRequest) {
      this.handleBarcode({ value: this.$route.params.deliveryRequest, type: 'DS/DR' })
    }

    if (this.outsideDeliveryRequest) {
      let value = this.outsideDeliveryRequest
      if (this.outsideDeliveryRequest.id) {
        value = this.outsideDeliveryRequest.id
      }

      this.handleBarcode({ value: value, type: 'DS/DR' })
    }

    if (this.deliveryRequest?._embedded?.deliveryService) {
      this.carrier = this.deliveryRequest._embedded.deliveryService
    }

    if (this.queue && this.queue.settings && this.queue.settings['load-requests-automatically']) {
      this.loadDeliveryRequests(true)
    }

    if (this.deliveryRequest && this.queue?.settings && this.queue.settings['generate-shipping-manifest']) {
      this.autoGenerateManifest()
    } else {
      this.updateSettings({ consumption: null })
    }
  },
  unmounted () {
    this.setHasDRChange(false)
    if (this.deliveryRequest && this.deliveryRequest.id && !this.lockedDeliveryRequests.includes(this.deliveryRequest.id)) {
      this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)
    }

    this.resetDeliveryRequest()

    if (this.deliveryRequest) {
      this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)

      this.setDeliveryRequest(null)
    }

    this.$service.printer.turnOffReconnect()
    this.printerSub?.unsubscribe()
  },
  methods: {
    ...mapActions([
      'loadDeliveryServices',
      'loadRates',
      'loadDeliveryRequest',
      'saveDeliveryRequest',
      'printLabelByRequest',
      'loadServicePoints',
      'refreshDeliveryRequest',
      'loadSenders',
      'saveDeliveryRequestByPayload',
      'printLabelByRequestBySize',
      'loadPreprocessingTaskQuery',
      'printLabelByRequest',
      'printLabel',
      'loadDeliveryRequests',
      'loadDeliveryRequestByBarcode',
      'executeForm',
      'loadConsumptions',
      'loadDeliveryRequest',
      'printBoxLabel',
    ]),
    ...mapMutations([
      'updateDeliveryRequest',
      'resetDeliveryRequest',
      'setNewDeliveryRequest',
      'addErrorNotification',
      'setDeliveryRequests',
      'removeLockedDeliveryRequest',
      'addLockedDeliveryRequest',
      'updateCleanDeliveryRequest',
      'updateDeliveryRequestEmbedded',
      'setHasDRChange',
      'addRouteToShortcuts',
      'removeRouteToShortcuts',
      'setMode',
      'setQueue',
      'setDeliveryRequest',
      'setPlaces',
      'setWidth',
      'setHeight',
      'setLength',
      'setWeight',
      'addWarningNotification',
      'updateSettings',
      'setInterceptAutoClose',
      'addNotification',
    ]),
    handleFinalPrice () {
      let finalPrice = 0
      if (this.deliveryRequest) {
        if ( this.deliveryRequest.estimatedCost) {
          finalPrice += this.deliveryRequest.estimatedCost
        }
        if (this.deliveryRequest.retailPrice) {
          finalPrice += this.deliveryRequest.retailPrice
        }
        if (this.deliveryRequest.tax) {
          finalPrice += this.deliveryRequest.tax
        }
      }

      if (this.deliveryRequest._embedded.order) {
        if (this.deliveryRequest._embedded.order.discountPrice) {
          finalPrice -= this.deliveryRequest._embedded.order.discountPrice
        }
      }
      return finalPrice
    },
    handlePaidPrice () {
      let finalPrice = this.handleFinalPrice()
      let paidPrice = 0
      if (this.deliveryRequest) {
        if ( this.deliveryRequest.payment) {
          paidPrice = finalPrice - this.deliveryRequest.payment
        }
      }

      return paidPrice
    },
    isDisabledDomestic () {
      const options = getRouteOptions('address')

      if (options.additionalProperties && options.additionalProperties.disabledFor) {
        const disabledFor = options.additionalProperties.disabledFor

        if (disabledFor.appOptions && disabledFor.appOptions.includes(this.appOptions.id)) {
          this.disabledDomestic = true
          return
        }
      }

      return
    },
    handleTrackingNumber (dr) {
      this.updateDeliveryRequest({ trackingNumber: dr.trackingNumber })
      this.updateCleanDeliveryRequest({ trackingNumber: dr.trackingNumber })
    },
    handleGetDocument () {
      this.$refs.documentModal.open(this.order)
    },
    handleEAVSubmit (eav) {
      this.updateDeliveryRequest({ eav })
    },
    handlePlaces (places) {
      this.updateDeliveryRequest({ places })

      if (this.deliveryRequest.id) {
        this.updateCleanDeliveryRequest({ places })
      }
    },
    handleUnlock () {
      if (this.isLockedDR) {
        this.isManuallyLocked = false
        return this.removeLockedDeliveryRequest(this.deliveryRequest.id)
      }

      this.isManuallyLocked = true
      return this.addLockedDeliveryRequest(this.deliveryRequest.id)
    },
    submitOpenRequest () {
      if (this.lockedDeliveryRequests.includes(this.deliveryRequest.id)) {
        return
      }

      this.$channels.user.publish('openDeliveryRequest', this.deliveryRequest.id)
    },
    handleDeliveryServicesIds (ids) {
      this.deliveryServicesIds = ids
    },
    handleCurierChange () {
      this.isCourier = !this.isCourier
    },
    handleLocalityChange (locality) {
      this.updateEmbedded({
        recipientLocality: locality,
        recipientAddress: {
          ...this.deliveryRequest._embedded.recipientAddress,
          _embedded: {
            ...this.deliveryRequest._embedded.recipientAddress._embedded,
            locality: locality
          }
        }
      })
    },
    handleUpdateSender (sender) {
      this.updateEmbedded({ sender })
    },
    showPlaces () {
      this.$refs.productsModal.open(this.deliveryRequest.id, this.deliveryRequest.places, !!(this.deliveryRequest.eav || {})['delivery-request-products-order'])
    },
    refresh () {
      this.loadDeliveryRequest(this.$route.params.id)
    },
    handleLocality (locality) {
      if (
          locality.id &&
          (locality.postcode && (this.deliveryRequest._embedded.recipientAddress || {}).postcode !== locality.postcode)
      ) {
        const recipientAddress = (this.deliveryRequest._embedded.recipientAddress || { _embedded: {} })

        if (!recipientAddress._embedded) {
          recipientAddress._embedded = {}
        }

        recipientAddress._embedded.locality = locality

        this.updateEmbedded({
          recipientAddress: {
            ...recipientAddress,
            postcode: locality.postcode
          },
          recipientLocality: locality
        })
      }
    },
    updateServicePoint (servicePoint) {
      this.updateEmbedded({ servicePoint })

      if (servicePoint && servicePoint._embedded) {
        const locality = servicePoint._embedded.locality
        this.handleLocality(locality)
      }

      this.calculatorModal = false
    },
    handleHistory () {
      this.historyModal = true
    },
    handleOpenModal () {
      this.calculatorModal = true
    },
    handleExport () {
      this.$refs.exportModal.open(this.deliveryRequest)
    },
    handleDiscard () {
      if (this.window) {
        this.$emit('close')
        return
      }

      this.$router.go()
    },
    handleBack () {
      if (this.window) {
        this.$emit('close')
        return
      }

      this.$router.back()
    },
    updateDimensions (dimensions) {
      this.updateDeliveryRequest(dimensions)
        this.calculatorModal = false
    },
    updateProfile (recipient) {
      if(!recipient.phone) {
        this.updateDeliveryRequest({
          _embedded: {
            ...this.deliveryRequest._embedded,
            recipient
          },
          recipientName: recipient.name,
        })
      } else {
        this.updateDeliveryRequest({
          _embedded: {
            ...this.deliveryRequest._embedded,
            recipient
          },
          recipientName: recipient.name,
          recipientPhone: recipient.phone
        })
      }

    },
    updateRecipientAddress (address) {
      this.updateEmbedded({
        recipientAddress: address,
        recipientLocality: address._embedded.locality
      })
    },
    updateRate (rate , isDimensions) {
      this.updateDeliveryRequestEmbedded({ rate, deliveryService: rate.deliveryService, servicePoint: null })

      if (rate.raw) {
        this.updateDeliveryRequest({
            eav: {
                ...this.deliveryRequest?.eav,
                'delivery-services-calculation-result': rate.raw
            }
        })
      }

      // if(!isDimensions) {
      //    this.calculatorModal = isDimensions
      // }
    },
    updatePhone (recipientPhone) {
      this.updateEmbedded({ recipientPhone })
    },
    updateEmbedded (update) {
      this.updateDeliveryRequest({
        _embedded: {
          ...this.deliveryRequest._embedded,
          ...update
        }
      })
    },
    makeSaveDeliveryRequest () {
      const error = this.$utils.validate.deliveryRequest(this.deliveryRequest, !this.isInternational)

      if (error) {
        this.addErrorNotification(error)
        return
      }

      const payload = {
        redirectLink: undefined
      }

      if (!this.deliveryRequest.id) {
        payload.redirectLink = this.prefix + '/shipments'
      }

      const isNewDeliveryRequest = !this.deliveryRequest.id

      this.isSaveLoading = true
      this.saveDeliveryRequest(payload)
          .then(item => {
            if (isNewDeliveryRequest) {
              this.setDeliveryRequests([item, ...this.deliveryRequests])
            }

            if (this.window) {
              this.$emit('submit', item)
            }

            this.setHasDRChange(false)
          })
          .finally(() => {
            this.isSaveLoading = false
          })
    },
    checkTaskState () {
      if (this.task) {
        switch (this.task.state) {
          case 'new':
            this.step = 1
            break
          case 'packaging':
            this.step = 1
            break
          case 'packed':
            this.step = 2
            break
          case 'measuring':
            this.step = 3
            break
          case 'upload_error':
            this.step = 3
            break
          case 'error':
            this.step = 3
            break
          case 'measured' :
            this.step = 4
            break
          case 'prepared' :
            this.step = 5
            break
          case 'calculating':
            this.step = 6
            break
          case 'calculated':
            this.step = 7
            break
          case 'exported':
            this.step = 7
            break
          case 'closed':
            this.step = 8
            this.doneStep = true
            break
        }
      }
    },
    updateTimeDifference () {
      const timestamp = new Date(this.task?.updated?.date + ' ' + this.task?.updated?.timezone)
      if (!isNaN(timestamp)) {
        const currentTime = new Date()
        this.secondsSinceTimestamp = Math.floor((currentTime - timestamp) / 1000)
      } else {
        this.secondsSinceTimestamp = null
      }
    },
    kebabToCamel (obj) {
      if (obj && typeof obj === 'object') {
        const newObj = {}
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            const camelKey = key.replace(/-([a-z])/g, (match, group) => group.toUpperCase())
            newObj[camelKey] = obj[key]
          }
        }
        return newObj
      } else {
        return obj
      }
    },
    autoGenerateManifest () {
      let sendDate = this.getSendDate(this.deliveryRequest)

      const query = {
        per_page: 25,
        filter: [
          { type: 'eq', field: 'state', value: 'new' },
          { type: 'eq', field: 'sender', value: this.deliveryRequest._embedded.sender.id },
          { type: 'eq', field: 'deliveryService', value: this.deliveryRequest._embedded.deliveryService.id },
          { type: 'eq', field: 'shippingDate', value: sendDate }
        ],
      }

      if (this.currentConsumptionFilter != query) {
        return this.$service.deliveryServiceConsumption.getAll(query)
            .then(({ items }) => {
              if (items.length === 0) {
                const deliveryService = (this.deliveryRequest._embedded.deliveryService || {}).id
                const sender = (this.deliveryRequest._embedded.sender || {}).id
                const shippingDate = this.getSendDate(this.deliveryRequest)
                this.createNewConsumption({ sender, deliveryService, shippingDate }, query)
                    .then(consumption => {
                      this.updateSettings({ consumption })
                    })
                    // .then(() => {
                    //   this.deliveryRequest = {
                    //     ...this.deliveryRequest,
                    //     state: 'sent'
                    //   }
                    //
                    //   // this.measuredDR = [this.deliveryRequest.id, ...this.measuredDR]
                    //   // this.deliveryRequests = {
                    //   //   ...this.deliveryRequests,
                    //   //   items: this.deliveryRequests.items.filter(x => x.id !== this.deliveryRequest.id)
                    //   // }
                    //
                    //   // this.$emit('submit', this.deliveryRequest)
                    // })
                    .catch(error => {
                      this.deliveryRequest = { ...this.deliveryRequest, deliveryServiceStatus: error.message }
                      // this.deliveryRequests = {
                      //   ...this.deliveryRequests,
                      //   items: [
                      //     this.deliveryRequest,
                      //     ...this.deliveryRequests.items.filter(x => x.id !== this.deliveryRequest.id)
                      //   ]
                      // }
                    })
                    .finally(() => {
                      this.isLoading = false
                    })

                this.currentConsumptionFilter = query
              } else if (items.length === 1) {
                const consumption = items[0]

                this.updateSettings({ consumption })

                this.currentConsumptionFilter = query
              } else {
                this.addErrorNotification('Several consumptions returned')
              }

              return items
            })
      }
    },
    handleOpenOrder () {
      const backParam = this.$route.query.back

      this.setDeliveryRequest(null)

      if (backParam) {
        this.$router.push(backParam)
      }
    },
    createNewConsumption (data = {}, result) {
      const consumption = {
        ...data,
        comment: 'automatic created'
      }

      if (result) {
        consumption.result = result
      }

      return this.$service.deliveryServiceConsumption.save(consumption)
          .then(item => {
            this.loadedConsumptions.push(item)
            return item
          })
    },
    getSendDate (deliveryReqeust) {
      const today = new Date()
      today.setHours(0, 0, 0, 0)

      let sendDate = deliveryReqeust?.sendDate

      if (sendDate?.date) {
        const [datePart, timePart] = sendDate.date.split(' ')

        const [year, month, day] = datePart.split('-').map(Number)
        const [hour, minute, second] = timePart.split(':').map(Number)

        sendDate = new Date(year, month - 1, day, hour, minute, second)
        sendDate.setHours(0, 0, 0, 0)
      }

      if (sendDate < today) {
        sendDate = null
      }

      if (!sendDate && this.settings.default?.sendDate) {
        const today = new Date()
        const tomorrow = new Date()

        tomorrow.setDate(today.getDate() + 1)

        sendDate = new Date(this.settings.default.sendDate)
      }

      if (sendDate < today) {
        sendDate = today
      }

      return sendDate.toDateString()
    },
    getSize (item) {
      const sizes = item.places.reduce((acc, place) => {
        const placeSize = place.items.reduce((placeAcc, item) => {
          placeAcc.weight += Number(item.weight) || 0

          const itemDimensions = !item.dimensions || typeof item.dimensions === 'string'
              ? { x: 0, y: 0, z: 0 }
              : item.dimensions

          placeAcc.dimensions.x += itemDimensions.x
          placeAcc.dimensions.y += itemDimensions.y

          if (itemDimensions.z > placeAcc.dimensions.z) {
            placeAcc.dimensions.z = itemDimensions.z
          }

          return placeAcc
        }, { weight: 0, dimensions: { x: 0, y: 0, z: 0 } })

        acc.weight += placeSize.weight
        acc.dimensions.x += placeSize.dimensions.x
        acc.dimensions.y += placeSize.dimensions.y

        if (placeSize.dimensions.z > acc.dimensions.z) {
          acc.dimensions.z = placeSize.dimensions.z
        }

        return acc
      }, { weight: 0, dimensions: { x: 0, y: 0, z: 0 } })

      sizes.weight = this.getSizeValue(sizes.weight)
      sizes.dimensions.x = this.getSizeValue(sizes.dimensions.x)
      sizes.dimensions.y = this.getSizeValue(sizes.dimensions.y)
      sizes.dimensions.z = this.getSizeValue(sizes.dimensions.z)

      return sizes
    },
    getSizeValue (value) {
      return value <= 0
          ? 100
          : Number(value)
    },
    loadConsumptionsMulty (deliveryRequests) {
      const entities = deliveryRequests.reduce((acc, entity) => {
        if (entity._embedded && entity._embedded.sender && !acc.senders.includes(entity._embedded.sender.id)) {
          acc.senders.push(entity._embedded.sender.id)
        }

        if (entity._embedded && entity._embedded.deliveryService && !acc.deliveryServices.includes(entity._embedded.deliveryService.id)) {
          acc.deliveryServices.push(entity._embedded.deliveryService.id)
        }

        return acc
      }, { senders: [], deliveryServices: [] })

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'gte', field: 'created', value: createDate(new Date()) },
          { type: 'eq', field: 'state', value: 'new' },
          { type: 'in', field: 'sender', values: entities.senders },
          { type: 'in', field: 'deliveryService', values: entities.deliveryServices },
        ]
      }
      if (this.deliveryRequest && this.deliveryRequest.sendDate && this.deliveryRequest.sendDate.date) {
        query.filter.push({
          type: 'eq',
          field: 'shippingDate',
          value: this.deliveryRequest.sendDate.date.split(' ')[0]
        })
      }
      const params = JSON.stringify(query.filter)

      if (this.loadedConsumptionsData === params) {
        return
      }
      return this.$service.deliveryServiceConsumption.getAll(query)
          .then(result => {
            this.loadedConsumptions = result.items
            this.loadedConsumptionsData = params
            return result
          })
    },
    findItem () {
      const index = this.deliveryRequests.items.findIndex(dr => this.deliveryRequest.id === dr.id)
      return this.deliveryRequests.items.slice(index + 1).find(dr => !this.lockedDeliveryRequests.includes(dr.id))
    },
    handleIsServices () {
      this.isServicesOpen = !this.isServicesOpen
      const defaultData = {
        state: 'active',
        deliveryRequest: this.deliveryRequest.id,
      }

      this.$refs.deliveryRequestModalSide.open(this.deliveryRequest.id, this.deliveryRequest?._embedded?.order ? this.deliveryRequest._embedded.order.id : null, defaultData)
      this.catchAll = false
    },
    handleIsBeta (value) {
      this.isBeta = value
    },
    handleBoxDimensions (dimensions) {
      this.boxDimensions = dimensions
    },
    handlePoolChange (event) {
      this.placeItemsPool = event
    },
    handleMoved () {
      const query = {
        filter: [
          { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest?.id },
          { type: 'eq', field: 'type', value: 'simple' }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'desc' }
        ],
        page: this.boxesStats.page,
        'per_page': 250
      }

      const queryPool = {
        filter: [
          { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest?.id },
          { type: 'eq', field: 'type', value: 'pool' }
        ],
        'order-by': [
          { type: 'field', field: 'priority', direction: 'desc' }
        ],
        'per_page': 250
      }

      return this.$service.deliveryServicePlace.getAll(queryPool)
          .then(({ items }) => {
            if (items.length > 0) {
              this.placeItemsPool = items[0]
            }

            this.$service.deliveryServicePlace.getAll(query)
                .then((result) => {
                  this.isLoadingFinished = true

                  this.boxesStats.count = result.totalItems
                  this.boxesStats.page = result.page
                  this.boxesStats.total = result.totalPages

                  this.handlePlaceChange(result.items)
                })
          })
    },
    handleRemove (place, item) {
      let updatedPlace = place

      const places = this.deliveryRequest.places
          .reduce((acc, p) => {
            if (p.id === place.id) {
              p.items = p.items.filter(i => i.id !== item.id)
              updatedPlace = p

              if (p.items.length <= 0) {
                return acc
              }
            }

            acc.push(p)
            return acc
          }, [])

      this.$service.deliveryServicePlaceItems.save({ state: 'deleted' }, item.id)
      return this.savePlace(updatedPlace)
          .then(() => {
            this.handlePlaceChange(places)
          })
    },
    savePlace (place) {
      if (place.items.length <= 0) {
        return this.$service.deliveryServicePlace.save({ state: 'deleted' }, place.id)
      }

      const items = place.items.map(i => {
        const item = this.createNewItem(i)
        item.place = place.id
        return item
      })

      return this.$service.deliveryServicePlace.save({ deliveryRequest: place._embedded.deliveryRequest.id }, place.id)
    },
    createNewItem (item) {
      const blockedFields = ['_links', 'id', '_embedded', 'created', 'updated', 'price']

      const newItem = Object.keys(item).reduce((c, key) => {
        if (blockedFields.includes(key)) {
          return c
        }

        if (item[key] === undefined || item[key] === null) {
          return c
        }

        c[key] = item[key]
        return c
      }, {})

      if (!newItem.dimensions || typeof newItem.dimensions === 'string') {
        newItem.dimensions = {
          x: 1,
          y: 1,
          z: 1
        }
      }

      return newItem
    },
    updateDimension (item) {
      this.handlePlaceMeasure({
        type: 'db',
        value: `${item.weight}/${item.dimensions.x}/${item.dimensions.y}/${item.dimensions.z}`,
        raw: `${item.weight}/${item.dimensions.x}/${item.dimensions.y}/${item.dimensions.z}`
      })
      this.isOpenCalculator = false
    },
    openCalculator () {
      this.isOpenCalculator = true
    },
    openEditor () {
      this.isOpenEditor = true
    },
    loadDeliveryRequests (ifMounted = false) {
      this.isLoading = true

      let sendDate

      const newFilters = this.filter.filter(x => {
        if (x.field === 'sendDate') {
          sendDate = x
          return false
        }

        return true
      })

      const orx = {
        type: 'orx',
        conditions: [
          { type: 'isnull', field: 'sendDate' }
        ],
        where: 'or'
      }

      if (sendDate) {
        orx.conditions.push(sendDate)
        newFilters.unshift(orx)
      }

      const query = {
        page: this.deliveryRequests.page >= this.deliveryRequests.totalPages
            ? this.deliveryRequests.page
            : this.deliveryRequests.page + 1,
        per_page: 25,
        'order-by': [
          { type: 'field', field: 'created', direction: 'desc' }
        ],
        filter: newFilters
      }

      if (this.search && this.search[this.search.length - 1] !== '*' && this.search[this.search.length - 2] !== ':' && !query.search.includes('%')) {
        const end = this.search[this.search.length - 1] === ':'
            ? '*'
            : ':*'

        query.search = this.search + end
      } else if (query.search.includes('%')) {
        query.search = this.search
      }

      return this.$service.deliveryRequest.getAll(query)
          .then(result => {
            return this.loadConsumptionsMulty(result.items)
                .then(() => {
                  if (ifMounted) {
                    this.deliveryRequests = result
                    this.handleBarcode({ raw: result.items[0].id, value: result.items[0].id, type: 'skip' })
                    // this.loadDeliveryRequest(result.items[0].id)
                  }
                  return result
                })
          })
          .finally(() => {
            this.isLoading = false
          })
    },
    loadItems () {
      if (this.deliveryRequest && this.deliveryRequest.dimensions) {
        let query = {
          debug: !!this.appOptions.debug,
          clearCache: !!this.appOptions.debug,
          weight: this.deliveryRequest.weight || this.appOptions.defaultDimensions.weight || 1,
          width: this.deliveryRequest?.dimensions.x || this.appOptions?.defaultDimensions?.dimensions.x || 1,
          height: this.deliveryRequest?.dimensions.y || this.appOptions?.defaultDimensions?.dimensions.y || 1,
          length: this.deliveryRequest?.dimensions.z || this.appOptions?.defaultDimensions?.dimensions.z || 1,
          divisor: 5000
        }

        if (this.appOptions.calculator && this.appOptions.calculator.deliveryServices) {
          query.deliveryServices = this.appOptions.calculator.deliveryServices
        }

        return HALApiService.get(`/delivery-services/calculator/${this.deliveryRequest.id}`, query)
            .then(data => {
              this.rates = data.rates
            })
      } else if (this.deliveryRequest && !this.deliveryRequest.dimensions) {
        let query = {
          debug: !!this.appOptions.debug,
          clearCache: !!this.appOptions.debug,
          weight: this.deliveryRequest.weight || this.appOptions.defaultDimensions.weight || 1,
          width: this.appOptions.defaultDimensions.dimensions.x || 1,
          height: this.appOptions.defaultDimensions.dimensions.y || 1,
          length: this.appOptions.defaultDimensions.dimensions.z || 1,
          divisor: 5000
        }

        if (this.appOptions.calculator && this.appOptions.calculator.deliveryServices) {
          query.deliveryServices = this.appOptions.calculator.deliveryServices
        }

        return HALApiService.get(`/delivery-services/calculator/${this.deliveryRequest.id}`, query)
            .then(data => {
              this.rates = data.rates
            })
      }
    },
    handleConsumptionFilter (filter) {
      this.consumptionFilter = filter
      this.$refs.formBuilder.$refs.form.$refs.consumption.$refs.consumption.reset()
    },
    handlePrinterSettingsClose () {
      this.isFocused = false
    },
    dimensionsValidation (val) {
      return val > 0 || val === null || 'Minimum value is 0.1'
    },
    handlePrint () {
      this.setInterceptAutoClose(true)
      this.printLabel({ mode: 'print' })
    },
    onFocus () {
      this.isFocused = true
    },
    onFocusOut () {
      this.isFocused = false
    },
    handleOpenCloseSettingsModal (isOutside) {
      if (isOutside && typeof isOutside === 'boolean') {
        this.settingsModal = false
      } else {
        this.settingsModal = !this.settingsModal
      }

      this.isFocused = this.settingsModal
    },
    // Validate sizes depending on weight control
    isNotValidDimensions () {
      return !this.width || this.width < 0 ||
          !this.height || this.height < 0 ||
          !this.length || this.length < 0 ||
          !this.weight || this.weight < 0
    },
    isNotValidDimensionsPlace () {
      return !this.placeSize.weight || this.placeSize < 0 ||
          !this.placeSize.dimensions.x || this.placeSize < 0 ||
          !this.placeSize.dimensions.y || this.placeSize < 0 ||
          !this.placeSize.dimensions.z || this.placeSize < 0
    },
    measuring (barcode) {
      const actions = {
        X: value => {
          this.setWidth(value)
        },
        Y: value => {
          this.setHeight(value)
        },
        Z: value => {
          this.setLength(value)
        },
        DB: value => {
          const setters = {
            0: (v) => {
              this.setWeight(v)
            },
            1: (v) => {
              this.setWidth(v)
            },
            2: (v) => {
              this.setHeight(v)
            },
            3: (v) => {
              this.setLength(v)
            }
          }

          value.split('/').forEach((value, index) => {

            if (value && value > 0) {
              setters[index](value)
            }
          })
        }
      }

      if (actions[barcode.type]) {
        actions[barcode.type](barcode.value)
      } else {
        this.setWeight(barcode.value)
      }
    },
    arrangePlaces (places) {
      if (this.places) {
        this.places.sort((a, b) => b.priority - a.priority)
      }
    },
    getCurrentPlace () {
      if (this.places) {
        return this.places[0]
      }

      return null
    },
    handlePlaceMeasure (barcode) {
      const actions = {
        X: value => {
          this.placeSize.dimensions.x = value
        },
        Y: value => {
          this.placeSize.dimensions.y = value
        },
        Z: value => {
          this.placeSize.dimensions.z = value
        },
        DB: value => {
          const setters = {
            0: (v) => {
              this.setWeight(v)
              this.placeSize.weight = v
            },
            1: (v) => {
              this.setWidth(v)
              this.placeSize.dimensions.x = v
            },
            2: (v) => {
              this.setHeight(v)
              this.placeSize.dimensions.y = v
            },
            3: (v) => {
              this.setLength(v)
              this.placeSize.dimensions.z = v
            }
          }

          value.split('/').forEach((value, index) => {
            if (value && value > 0) {
              setters[index](value)
            }
          })
        },
        'DS/RP': value => {
          this.handleGetLabel({
            id: value
          })
        }
      }

      if (barcode.type) {
        if (actions[barcode.type.toUpperCase()]) {
          actions[barcode.type.toUpperCase()](barcode.value)
        } else {
          this.addErrorNotification('Barcode type is not recognized')
        }
      } else {
        this.placeSize.weight = barcode.value
      }

      if (!this.placeSize.id && this.getCurrentPlace()) {
        let currentPlace = this.getCurrentPlace()

        this.placeSize.id = currentPlace.id
      }

      if (barcode.raw) {
        this.placeSubmit()
      }
    },
    handleGetLabel (place) {
      this.setInterceptAutoClose(true)
      this.printBoxLabel({ mode: 'print', place: place })
    },
    placeSubmit () {
      if (
          this.placeSize.id &&
          this.placeSize.weight &&
          this.placeSize.weight > 0 &&
          this.placeSize.dimensions.x &&
          this.placeSize.dimensions.x > 0 &&
          this.placeSize.dimensions.y &&
          this.placeSize.dimensions.y > 0 &&
          this.placeSize.dimensions.z &&
          this.placeSize.dimensions.z > 0
      ) {
        return this.$service.deliveryServicePlace.save({ ...this.placeSize, id: undefined }, this.placeSize.id)
            .then(() => {
              // this.placeSizes = []
              const index = this.places.findIndex(place => {
                return place.id === this.placeSize.id
              })

              if (index > -1) {
                let places = this.places
                places[index] = { ...places[index], ...this.placeSize }

                let deliveryRequest = this.deliveryRequest
                deliveryRequest.places = places
                this.setDeliveryRequest(deliveryRequest)
                this.setPlaces(places)
                this.handlePlaceChange(places)
              }

              return this.execute().then(() => {
                this.placeSize = createPlaceSize()
              })
            })
            .finally(() => {
              this.$service.printer.clearBlob()
            })
      }
    },
    calculateOverallSizes (places) {
      places = Object.values(places)
      if (!places.length) {
        return { weight: 0, dimensions: { x: 0, y: 0, z: 0 } }
      }

      return places.reduce((acc, place) => {
        acc.weight += Number(place.weight)

        const x = Number(place.dimensions?.x)
        if (x > acc.dimensions?.x) {
          acc.dimensions.x = x
        }

        const y = Number(place.dimensions?.y)
        if (y > acc.dimensions?.y) {
          acc.dimensions.y = y
        }

        const z = Number(place.dimensions?.z)
        if (z > acc.dimensions?.z) {
          acc.dimensions.z = z
        }

        return acc
      }, { weight: 0, dimensions: { x: 0, y: 0, z: 0 } })
    },
    loadBarcode (barcode) {
      this.isLoading = true
      return this.loadDeliveryRequestByBarcode(barcode, true)
          .then(() => {
            if (parseInt(this.$route.params.deliveryRequest) !== parseInt(this.deliveryRequest.id)) {
              this.$router.push(`/workstation/labelling/regular/${this.queue.id}/${this.deliveryRequest.id}`)
            }

            if (this.deliveryRequest) {
              this.$channels.user.publish('openDeliveryRequest', this.deliveryRequest.id)
            }

            // This should be disabled because on change of the delivery request we attempt to reload places
            if (0 && !Array.isArray(this.deliveryRequest.places)) {
              const query = {
                filter: [
                  { type: 'eq', field: 'deliveryRequest', value: this.deliveryRequest.id }
                ],
                'order-by': [
                  { type: 'field', field: 'priority', direction: 'asc' }
                ],
                'per_page': 250
              }

              return this.$service.deliveryServicePlace.getAll(query)
                  .then(({ items }) => {
                    this.handlePlaceChange(items)
                    this.isLoad = false
                    this.isMeasured = false
                  })
            }

            this.isLoad = false
            this.isMeasured = false

            if (!this.task || this.taskCreateUnlockStates.includes(this.task?.state) || this.task.state === 'closed') {
              this.isLabelGenerated = false
            }
          })
          .catch(() => {
            this.isLoad = false
            this.isLoading = false
          }).finally(() => {
            this.isLoad = false
            this.isLoading = false
          })
    },
    handlePrintOrderLabel () {
      this.$service.order.get(this.deliveryRequest._embedded.order.id)
          .then(e => {
            const shop = tr(e?._embedded?.shop?.name)
                + '\n' + e?.shipmentDate
                + '\n' + this.deliveryRequest?._embedded?.deliveryService?.name

            this.$service.printer.generateBarcode({
              barcode: `P/O/${e.id}*`,
              label: `${shop}`,
              size: '88x44',
              fontSize: '13'
            })
          })
    },
    handleBarcode (barcode) {
      this.scanInput = barcode

      if (barcode.type === 'skip') {
        this.setWidth(null)
        this.setHeight(null)
        this.setLength(null)
        this.setWeight(null)

        this.isLoad = true

        if (this.deliveryRequest) {
          this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)
        }

        return this.loadBarcode(barcode)
      }

      if (barcode.type === 'S/S' && this.deliveryRequest) {
        const query = {
          page: 1,
          filter: [
            { type: 'eq', field: 'barcode', value: barcode.value }
          ]
        }
        this.isLoad = true
        return this.$service.supply.getAll(query)
            .then(items => {
              if (items.items.length === 0) {
                this.addErrorNotification(`No supply with barcode ${barcode.raw} found`)
              }
              let supply = items.items[0]

              if (supply.type === 'package') {
                let currentPlace = null
                if (this.deliveryRequest.places && this.deliveryRequest.places.length === 1) {
                  currentPlace = _.cloneDeep(this.deliveryRequest.places[0])
                }

                const data = {
                  deliveryRequest: this.deliveryRequest.id,
                  dimensions: supply.dimensions,
                  volume: null,
                  weight: null,
                  eav: { ['storage-supply-id']: supply.id }
                }

                return this.$service.deliveryServicePlace.save(data)
                    .then(place => {
                      const places = [...this.deliveryRequest.places, place]
                      this.handlePlaceChange(places)
                      this.isLoad = false
                    })
                    .then(() => {
                      if (currentPlace) {
                        this.$service.deliveryServicePlace.save({ eav: { ['delivery-services-request-place-is-pool']: true } }, currentPlace.id)
                      }
                    })
              } else {
                const result = {
                  count: 1,
                  deliveryRequest: this.deliveryRequest.id,
                  name: supply.name,
                  price: supply.price,
                  supply: supply.id
                }
                return this.$service.deliveryServicesRequestService.save({
                  ...result,
                  deliveryRequest: this.deliveryRequest.id
                })
                    .then(item => {
                      const services = [item, ...this.services]
                      this.services = services
                      this.isLoad = false
                    })
              }
            })
      }

      if (this.scanType === 'delivery-request' || barcode.type === 'P/O' || barcode.type === 'DS/DR') {
        if (this.lockedDeliveryRequests.includes(Number(barcode.value))) {
          this.addErrorNotification('Shipping request is already loaded from someone!')
          return
        }

        if (!this.isMeasured) {
          return this.$refs.confirmModal.show({ description: 'Skip this shipment?' })
              .then(isOk => {
                if (isOk) {
                  this.isMeasured = true
                  this.setDeliveryRequest(null)
                  this.handleBarcode(barcode)
                }
              })
        }

        // When we load next request we must reset sizes
        this.setWidth(null)
        this.setHeight(null)
        this.setLength(null)
        this.setWeight(null)

        this.isLoad = true

        if (this.deliveryRequest) {
          this.$channels.user.publish('closeDeliveryRequest', this.deliveryRequest.id)
        }

        return this.loadBarcode(barcode)
      }

      if (this.isMultiPlaceGood) {
        return this.handlePlaceMeasure(barcode)
      }

      this.measuring(barcode)

      if (!this.isNotValidDimensions()) {
        if (Number(this.settings.maxWeight) < this.weight && this.settings.weightControl) {
          return this.$refs.confirmModal.show({ description: 'Weight is over maximum. Are you sure that you want to measure it?' })
              .then(isOk => {
                if (isOk) {
                  this.execute()
                }
              })
        } else {
          this.execute()
        }
      }
    },
    useDefaultSettings () {
      const sizes = this.calculateOverallSizes(this.places)
      if (!sizes.weight) {
        this.setWeight(this.settings.default.weight)
      } else {
        this.setWeight(sizes.weight)
      }

      if (!sizes.dimensions.x) {
        this.setWidth(this.settings.default.width)
      } else {
        this.setWidth(sizes.dimensions.x)
      }

      if (!sizes.dimensions.y) {
        this.setHeight(this.settings.default.height)
      } else {
        this.setHeight(sizes.dimensions.y)
      }

      if (!sizes.dimensions.z) {
        this.setLength(this.settings.default.length)
      } else {
        this.setLength(sizes.dimensions.z)
      }

      if (Object.keys(this.placeSizes).length > 0 && Object.keys(this.placeSizes).length !== this.deliveryRequest.places.length) {
        console.log(this.placeSizes)
      } else {
        // this.placeSizes = []

        return this.execute()
      }
    },
    handleSend () {
      if (this.places) {
        console.log(this.places)
        const sizes = this.calculateOverallSizes(this.places)
        if (this.queue?.settings && !this.queue?.settings['set-predefined-measurements']) {
          if (!sizes.dimensions.z || !sizes.dimensions.x || !sizes.dimensions.y || !sizes.weight) {
            this.noSizesCheck = true
            return
          }
        }
      }

      if (this.deliveryRequest && this.queue?.settings && this.queue?.settings && this.queue?.settings['trust-incoming-measurements']) {
        if (this.deliveryRequest.weight) {
          this.handlePlaceMeasure({ type: '', value: this.deliveryRequest.weight, raw: this.deliveryRequest.weight })
          this.setWeight(this.deliveryRequest.weight)
        }

        if (this.deliveryRequest.dimensions.x) {
          this.handlePlaceMeasure({
            type: 'x',
            value: this.deliveryRequest.dimensions.x,
            raw: this.deliveryRequest.dimensions.x
          })
          this.setWidth(this.deliveryRequest.dimensions.x)
        }
        if (this.deliveryRequest.dimensions.y) {
          this.handlePlaceMeasure({
            type: 'y',
            value: this.deliveryRequest.dimensions.y,
            raw: this.deliveryRequest.dimensions.y
          })
          this.setHeight(this.deliveryRequest.dimensions.y)
        }
        if (this.deliveryRequest.dimensions.z) {
          this.handlePlaceMeasure({
            type: 'z',
            value: this.deliveryRequest.dimensions.z,
            raw: this.deliveryRequest.dimensions.z
          })
          this.setLength(this.deliveryRequest.dimensions.z)
        }
      } else if (this.places) {
        const sizes = this.calculateOverallSizes(this.places)

        if (!sizes.weight) {
          if (this.settings.setPredefinedMeasurements) {
            this.setWeight(this.settings.default.weight)
          }
        } else {
          this.setWeight(sizes.weight)
        }

        if (!sizes.dimensions.x) {
          if (this.settings.setPredefinedMeasurements) {
            this.setWidth(this.settings.default.width)
          }
        } else {
          this.setWidth(sizes.dimensions.x)
        }

        if (!sizes.dimensions.y) {
          if (this.settings.setPredefinedMeasurements) {
            this.setHeight(this.settings.default.height)
          }
        } else {
          this.setHeight(sizes.dimensions.y)
        }

        if (!sizes.dimensions.z) {
          if (this.settings.setPredefinedMeasurements) {
            this.setLength(this.settings.default.length)
          }
        } else {
          this.setLength(sizes.dimensions.z)
        }
      }
      // this.setWeight(sizes.weight)
      // this.setWidth(sizes.dimensions.x)
      // this.setHeight(sizes.dimensions.y)
      // this.setLength(sizes.dimensions.z)

      if (Object.keys(this.placeSizes).length > 0 && Object.keys(this.placeSizes).length !== this.deliveryRequest.places.length) {
        console.log(this.placeSizes)
      } else {
        // this.placeSizes = []

        return this.execute()
      }
    },
    handlePlaceChange (places, totalBoxes, boxPage, totalBoxPages) {
      if (this.deliveryRequest) {
        this.updateDeliveryRequest({ places })
        if (places.length > 0) {
          this.isMultiPlaceGood = true
        } else {
          this.isMultiPlaceGood = false
        }

        if (this.isBeta) {
          if (places) {
            places = places.filter(e => !e.eav['delivery-services-request-place-is-pool'])
          }

          this.placesToUse = _.cloneDeep(places)
          if (this.isMultiPlaceGood) {
            this.placeSize = createPlaceSize(this.placesToUse.shift())
            this.placeSizes = []
          }
        } else {
          let places2 = _.cloneDeep(places)
          this.setPlaces(Object.values(places2))

          if (this.isMultiPlaceGood) {
            this.placeSize = createPlaceSize(this.places.filter(e => !e.eav['delivery-services-request-place-is-pool']).sort((a, b) => {
              return a.priority - b.priority
            }).shift())
            this.placeSizes = []
          }
        }
      }
    },
    execute () {
      return Promise.resolve(this.isFirstAction && !this.settings.consumption)
          .then(showModal => {
            if (showModal) {
              this.onFocus()

              if (this.appOptions.labellingSettings?.notification?.shippingManifest) {
                const description = this.$t(this.appOptions.labellingSettings.notification.shippingManifest)

                return this.$refs.confirmModal.show({ description })
              }

              return true
            }

            return true
          }).then(isOk => {
            if (this.appOptions.labellingSettings?.notification?.labelConfirm) {
              const description = this.$t(this.appOptions.labellingSettings.notification.labelConfirm)

              return this.$refs.confirmModal.show({ description })
            }

            return true
          })
          .then(isOk => {
            this.onFocusOut()

            if (isOk) {
              let place = null
              if (this.places?.length >= 1) {
                place = this.places[0]
              }

              if (place?.weight) {
                this.setWeight(place.weight)
              }

              if (place?.dimensions?.x) {
                this.setWidth(place.dimensions?.x)
                this.setHeight(place.dimensions?.y)
                this.setLength(place.dimensions?.z)
              }

              this.executeForm()
                  .then((result) => {
                    this.isMeasured = true

                    setTimeout(() => {
                      this.$emit('finish-executing')
                    }, 100)
                  }).catch((err) => {
                this.validStatusCodes.push(400)
                if (this.validStatusCodes.includes(err.status)) {
                  this.isLabelGenerated = true
                } else {
                  this.isLabelGenerated = false
                }

                if (this.deliveryRequest?.id) {
                  return this.loadBarcode({
                    value: this.deliveryRequest?.id
                  })
                }
              })
            }

            if (isOk && this.isFirstAction) {
              this.isFirstAction = false
            }
          })
          .catch(() => {
            this.isMeasured = true
          })
          .finally(() => {
            if (this.noSizesCheck) {
              this.noSizesCheck = false
            }
          })
    },
    createQuery (params) {
      return {
        per_page: 25,
        'order-by': [
          {
            type: 'field',
            field: 'created',
            direction: 'desc'
          }
        ],
        filter: [
          {
            type: 'in',
            field: 'state',
            values: ['new', 'checking']
          }
        ],
        ...params
      }
    },
    loadNextItems (search, page, loader) {
      const loaders = {
        consumptions: this.loadConsumptions
      }

      if (typeof loaders[loader] !== 'function') {
        this.addErrorNotification('Items loader is unknown!')
        return
      }

      const query = this.createQuery({ search, page })

      return loaders[loader](query)
    },
    handleChange (type) {
      const types = {
        consumptions: {
          getter: 'consumptions',
          page: 'consumptionsPage',
          loadedValue: 'loadedConsumptions'
        }
      }

      const current = types[type]

      if (!current) {
        this.addErrorNotification('Unable to handle filter change!')
        return
      }

      if (this[current.page] <= 1) {
        this[current.loadedValue] = this[current.getter]
      } else {
        this[current.loadedValue] = [
          ...this[current.loadedValue],
          ...this[current.getter]
        ]
      }
    },
    openDR (dr) {
      window.open(`/#/outbound/shipments/entity/${dr}`, '_blank')
    },
    openOrder (order) {
      this.$service.order.get(order.id)
          .then(e => {
            window.open(`/#/outbound/orders/entity/${e.type}/${e.id}`, '_blank')
          })
    },
    handleCatchAll (value) {
      this.catchAll = value
    },
    doCopy (content) {
      return doCopy(content)
    },
    handleAddSupply (deliveryRequest) {
      const defaultData = {
        state: 'active',
        deliveryRequest: deliveryRequest.id,
      }

      this.$refs.deliveryRequestModal.open(deliveryRequest.id, defaultData)
      this.catchAll = false
    },
    closeAreYouSure () {
      this.areYouSure = false
    },
    openAreYouSure () {
      this.areYouSure = true
    },
    handleEditTask (value, nextStep) {
      this.$service.deliveryServicePreprocessingTask.save({ state: value }, this.task.id)
          .then(() => {
            this.step = nextStep
            if (value === 'closed') {
              this.doneStep = true
            }
          })
    },
    handleCancelTask () {
      this.$service.deliveryServicePreprocessingTask.save({ state: 'cancel' }, this.task.id)
      this.areYouSure = false
    },
    saveSupplies (service) {
      const finalService = { name: service.name, price: service.price, supply: service.id, count: service.quantity }
      return this.$service.deliveryServicesRequestService.save({
        ...finalService,
        deliveryRequest: this.deliveryRequest.id
      })
          .then(item => {
            const test = [item, ...this.services]
            this.handleChangeServices(test)
          })
    },
    saveOrderSupplies (service) {
      if (this.deliveryRequest?._embedded?.order.id) {
        const finalService = { name: service.name, price: service.price, supply: service.id, count: service.quantity }
        return this.$service.productsService.save({ ...finalService, order: this.deliveryRequest._embedded.order.id })
            .then(item => {
              const test = [item, ...this.servicesOrder]
              this.handleChangeServicesOrder(test)
            })
      }
    },
    handleChangeServices (services) {
      this.services = services
    },
    handleChangeServicesOrder (services) {
      this.servicesOrder = services
    },
    checkLogo (dr) {
      if (dr && dr._embedded && dr._embedded.deliveryService) {
        if ((dr || {})._embedded.deliveryService.logo.includes('http')) {
          return (dr || {})._embedded.deliveryService.logo
        } else {
          return this.appOptions.defaultServer + dr._embedded.deliveryService.logo
        }
      }
    },
    handleItemSubmit (item, place, oldPlace, copyMade = false) {
      delete item.quantity
      let updatedPlace
      this.placesToUse = this.placesToUse.map(p => {
        if (p.id === place.id) {
          let hasItem = false

          p.items = p.items.map(i => {
            if (i.id === item.id) {
              hasItem = true
              return item
            }

            return i
          })

          if (!hasItem) {
            p.items.push(item)
          }
          if (copyMade && hasItem) {
            p.items.push(item)
          }
          updatedPlace = p
        }

        return p
      })
      if (updatedPlace) {
        this.placesToUse = this.placesToUse.map(e => {
          if (e.id === oldPlace.id && !copyMade) {
            e.items = e.items.filter(i => i.id !== item.id)
            this.$refs.drProduct.close()
            this.handleCloseItemModal()
            return e
          }
          this.$refs.drProduct.close()
          this.handleCloseItemModal()
          return e
        })
      }
      this.$refs.drProduct.close()
      this.handleCloseItemModal()
    },
    handleCloseItemModal () {
      this.$refs.labellingMovement.close()
    },
    handleSubmitWaiting (data) {
      return this.$service.storageItemEntity.post(data)
    },
    handleSendWaiting () {
      if (this.deliveryRequest._embedded.order) {
        return this.$refs.createItemModal.open(this.deliveryRequest._embedded.order)
      }

      this.$refs.createItemModal.open()
    }
  }
}
</script>

<style>
  .beta .col {
    font-size: 16px;
  }
  .edit-btn button {
    padding: 1px 6px;
  }
</style>

<script setup>
</script>